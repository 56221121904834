import { getDayByIndex } from '../../helpers'
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md'
import { useEffect, useState } from 'react'

const RationDay = (props) => {
  const day = new Date().getDay()
  const isToday = day === props.index + 1

  const [opened, setOpened] = useState(isToday)

  const rationSwitchHandler = () => {
    setOpened((prevState) => {
      return !prevState
    })
  }

  // Use effects
  useEffect(() => {
    setOpened(isToday)
  }, [isToday, props.index])

  const title = getDayByIndex(props.index)

  return (
    <div className="ration-day-wrapper">
      <div
        className={`ration-day-title ${
          isToday ? 'ration-day-title-today' : ''
        }`}
        onClick={rationSwitchHandler}
        title="Показать/Скрыть"
      >
        <div className="ration-day-switch">
          {opened ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
        </div>
        <h3>
          {title} {isToday && <span>(Сегодня)</span>}
        </h3>
      </div>
      {opened ? <div className="ration-day-recipes">{props.children}</div> : ''}
    </div>
  )
}

export default RationDay
