import AuthFooter from '../UI/AuthFooter'
import AuthHeaderMenu from '../UI/AuthHeaderMenu'
import Logo from '../UI/Logo'
import Message from '../UI/Message'
import ReactInputMask from 'react-input-mask'
import Sidebar from '../UI/Sidebar'
import { Fragment, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { API_URL } from '../../config/main'
import { processLogin } from '../../store/auth-actions'

import {
  getCookie,
  useBodyClass,
  validateEmail,
  validatePassword,
} from '../../helpers'

const Register = () => {
  useBodyClass('page-register')

  const dispatch = useDispatch()
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [disablePhoneMask, setDisablePhoneMask] = useState(false)

  const registerButtonTitle = isLoading
    ? 'Отправка данных...'
    : 'Зарегистрироваться'

  const field_name = useRef()
  const field_email = useRef()
  const field_password = useRef()
  const field_password_repeat = useRef()
  const field_phone = useRef()

  const formFocusHandler = (e) => {
    setMessage(null)
  }

  const switchPhoneMaskHandler = (e) => {
    setDisablePhoneMask((prevState) => {
      return !prevState
    })
  }

  const formSubmitHandler = (e) => {
    e.preventDefault()

    if (
      field_name.current.value !== '' &&
      field_email.current.value !== '' &&
      field_password.current.value !== '' &&
      field_password_repeat.current.value !== '' &&
      field_phone.current.value !== '' &&
      validateEmail(field_email.current.value)
    ) {
      if (
        field_password.current.value !== field_password_repeat.current.value
      ) {
        setMessage({ type: 'error', text: 'Пароли не совпадают.' })
      } else {
        if (!validatePassword(field_password.current.value)) {
          setMessage({
            type: 'error',
            text: 'Пароль должен быть не менее 5 символов.',
          })
        } else {
          // Get utm cookies
          const utm_source = getCookie('utm_source')
          const utm_medium = getCookie('utm_medium')
          const utm_campaign = getCookie('utm_campaign')
          const utm_content = getCookie('utm_content')

          processRegistration({
            name: field_name.current.value,
            email: field_email.current.value,
            password: field_password.current.value,
            phone: field_phone.current.value,
            utm_source: utm_source,
            utm_medium: utm_medium,
            utm_campaign: utm_campaign,
            utm_content: utm_content,
          })
        }
      }
    } else {
      setMessage({
        type: 'error',
        text: 'Пожалуйста верно заполните все поля.',
      })
    }
  }

  const processRegistration = useCallback(
    async (payload) => {
      setMessage(null)
      setIsLoading(true)

      try {
        const response = await fetch(API_URL + '?act=register', {
          method: 'POST',
          body: JSON.stringify(payload),
        })

        if (!response.ok) {
          throw new Error('Ошибка доступа к API.')
        }

        const data = await response.json()

        if (data.type === 'error') {
          setMessage({ type: 'error', text: data.message })
          setIsLoading(false)
        }

        if (data.type === 'success') {
          dispatch(processLogin(data.data, data.token))
          setIsLoading(false)
        }
      } catch (error) {
        setMessage({ type: 'error', text: error.message })
        setIsLoading(false)
      }
    },
    [dispatch]
  )

  return (
    <Fragment>
      <Sidebar>
        <div className="page-register-wrapper small-width-wrapper">
          <Logo />
          <h1>Регистрация</h1>
          <AuthHeaderMenu />
          {message && <Message content={message.text} type={message.type} />}
          <form onSubmit={formSubmitHandler} onFocus={formFocusHandler}>
            <div className="form-input-wrapper">
              <label>Ваше имя</label>
              <input
                type="text"
                placeholder="Анна Иванова"
                ref={field_name}
                required
              />
            </div>
            <div className="form-input-wrapper">
              <label>Ваш email</label>
              <input
                type="email"
                placeholder="email@gmail.com"
                ref={field_email}
                required
              />
            </div>
            <div className="form-input-wrapper">
              <label>Ваш телефон</label>
              {disablePhoneMask ? (
                <input
                  type="text"
                  placeholder="+7 (999) 999-99-99"
                  ref={field_phone}
                />
              ) : (
                <ReactInputMask mask="+9 (999) 999-99-99" ref={field_phone} />
              )}
              <div
                className="link margin-top-small"
                onClick={switchPhoneMaskHandler}
              >
                {disablePhoneMask
                  ? 'Включить форматирование номера'
                  : 'Отключить форматирование номера'}
              </div>
            </div>
            <div className="form-input-wrapper">
              <label>Ваш пароль</label>
              <input type="password" ref={field_password} required />
            </div>
            <div className="form-input-wrapper">
              <label>Повторите пароль</label>
              <input type="password" ref={field_password_repeat} required />
            </div>
            <div className="form-input-wrapper">
              <input
                type="submit"
                value={registerButtonTitle}
                disabled={isLoading}
              />
            </div>
          </form>
        </div>
        <AuthFooter />
      </Sidebar>
    </Fragment>
  )
}

export default Register
