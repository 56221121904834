import { SITE_URL, VERSION } from '../../config/main'

const AuthFooter = () => {
  return (
    <div className="auth-footer">
      <p>
        Используя интерактивную онлайн книгу вы принимаете
        <br />
        <a rel="noreferrer" href={`${SITE_URL}terms`} target="_blank">
          Договор оферты
        </a>{' '}
        и{' '}
        <a rel="noreferrer" href={`${SITE_URL}privacy-policy`} target="_blank">
          Политику конфиденциальности
        </a>
      </p>
      <p>&copy; 2023-2024 Mi.Рецепты v{VERSION}</p>
    </div>
  )
}

export default AuthFooter
