import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLogged: localStorage.getItem('isLogged'),
    user: JSON.parse(localStorage.getItem('user')),
    token: localStorage.getItem('token'),
  },
  reducers: {
    login(state, action) {
      state.user = action.payload.user
      state.token = action.payload.token
      state.isLogged = true
    },
    logout(state) {
      state.isLogged = false
      state.user = null
      state.token = null
    },
    setUserData(state, action) {
      state.user = action.payload.user
    },
  },
})

export const authActions = authSlice.actions

export default authSlice
