import Message from '../UI/Message'
import PageContent from '../UI/PageWrapper'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { API_REFRESH, API_URL, RECIPES_COUNT } from '../../config/main'
import { useBodyClass } from '../../helpers'

const Subscribe = () => {
  useBodyClass('page-subscribe')

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const [message, setMessage] = useState()
  const [discount, setDiscount] = useState(0)

  const promocodeField = useRef()

  const navigate = useNavigate()

  const [prices, setPrices] = useState({
    month_3: 0,
    month_6: 0,
    month_12: 0,
    unlimited: 0,
  })
  //const [isLoading, setIsLoading] = useState(false)

  const subscriptionHandler = (months) => {
    window.open(
      'https://mirecipes.ru/yookassa/subscribe.php?user_id=' +
        user.id +
        '&months=' +
        months +
        '&promocode=' +
        promocodeField.current.value,
      '_blank'
    )

    navigate('/account', { replace: false })
  }

  // Fetch rations list
  const fetchPrices = useCallback(async (payload) => {
    //setIsLoading(true)

    try {
      const response = await fetch(
        API_URL +
          `?act=getprices&user_id=${payload.user_id}&token=${payload.token}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      //setIsLoading(false)

      if (data.type === 'error') {
      }

      if (data.type === 'success') {
        setPrices(data.prices)
      }
    } catch (error) {
      //setIsLoading(false)
    }
  }, [])

  // Check promocode
  const checkPromocode = useCallback(async (payload) => {
    try {
      const response = await fetch(
        API_URL +
          `?act=checkpromocode&user_id=${payload.user_id}&token=${payload.token}&promocode=${payload.promocode}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      //setIsLoading(false)

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setMessage({
          type: 'success',
          text: 'Дополнительная скидка ' + data.data + '% активирована.',
        })

        setDiscount(data.data)
      }
    } catch (error) {
      //setIsLoading(false)
    }
  }, [])

  // Apply promocode
  const applyPromocodeHandler = (e) => {
    const promocode = promocodeField.current.value

    checkPromocode({
      user_id: user.id,
      token: token,
      promocode: promocode,
    })
  }

  // Prices calculation
  const final_prices = {
    month_3: Math.floor(
      prices.month_3 / (user.discount ? 2 : 1) -
        ((prices.month_3 / (user.discount ? 2 : 1)) * discount) / 100
    ),
    month_6: Math.floor(
      prices.month_6 / (user.discount ? 2 : 1) -
        ((prices.month_6 / (user.discount ? 2 : 1)) * discount) / 100
    ),
    month_12: Math.floor(
      prices.month_12 / (user.discount ? 2 : 1) -
        ((prices.month_12 / (user.discount ? 2 : 1)) * discount) / 100
    ),
    unlimited: Math.floor(
      prices.unlimited / (user.discount ? 2 : 1) -
        ((prices.unlimited / (user.discount ? 2 : 1)) * discount) / 100
    ),
  }

  // Use effects
  useEffect(() => {
    fetchPrices({
      user_id: user.id,
      token: token,
    })
  }, [fetchPrices, token, user.id])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchPrices({ user: user, user_id: user.id, token: token })
    }, API_REFRESH)

    return () => clearInterval(interval)
  }, [fetchPrices, user, token])

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Оплата тарифа</h1>
      </div>
      <div className="page-description">
        <h3>Как работает тариф?</h3>
        <p>
          <span>
            Выбрав тариф в личном кабинете вы оплачиваете выбранный период (3, 6
            , 12 месяцев или Вечный доступ). По окончанию оплаченного периода
            ваш аккаунт будет продлен автоматически на тот же период. Вы можете
            отменить следующий авто платеж в любое время в разделе{' '}
            <Link to="/account/">Мой аккаунт</Link>, если хотите продлять сервис
            вручную.
          </span>
        </p>
        {!user.discount ? (
          <p className="text-highlight">
            Покупателям{' '}
            <a
              href="https://book.miukagirl.ru/?utm_content=mr&utm_medium=mr&utm_source=mr&utm_campaign=mr"
              target="_blank"
              rel="noreferrer"
            >
              основной книги рецептов в PDF
            </a>{' '}
            (отдельно или в бандлах) доступна{' '}
            <strong>пожизненная скидка в 50%</strong> на оплату любого тарифа.
          </p>
        ) : (
          ''
        )}
        <p>
          В случае изменения тарифа оплаченный период сохранится и новый
          оплаченный период добавится к существующему.
        </p>
        <div>
          <h3>Промокод</h3>
          <p>
            Если у вас есть промокод введите его в поле ниже и нажмите
            применить. Промокод действует только на текущий один платеж.
          </p>
          <p className="promocode-form-wrapper">
            <input
              type="text"
              placeholder="Ваш промокод"
              ref={promocodeField}
              className="promocode"
            />
            <button className="button-fixed" onClick={applyPromocodeHandler}>
              Применить
            </button>
          </p>
        </div>
        {message && <Message content={message.text} type={message.type} />}
      </div>
      <div className="price-tables-wrapper">
        <div className="price-table">
          <div className="price-table-title">
            <h3>На 3 месяца</h3>
          </div>
          <div className="price-table-cost">
            {final_prices.month_3} руб/мес
            {!!user.discount && (
              <div className="discount">Скидка -50% активирована</div>
            )}
            {!!discount && (
              <div className="discount">Промокод -{discount}% активирован</div>
            )}
          </div>
          <div className="price-table-description">
            <ul>
              <li>
                <strong>Доступ на 3 месяца</strong>
              </li>
              <li>Доступ ко всем рецептам ({RECIPES_COUNT}+)</li>
              <li>Новые рецепты каждый месяц</li>
              <li>Генератор рационов</li>
              <li>Неограниченное кол-во рационов</li>
              <li>Неограниченный шоп-лист</li>
              <li>Неограниченный список избранного</li>
              <li>Синхронизация между устройствами</li>
            </ul>
          </div>
          <div className="price-table-button">
            <button onClick={() => subscriptionHandler(3)}>
              Оплатить {final_prices.month_3 * 3} рублей
            </button>

            <Link to="/ww-payment" className="button button-bordered">
              Оплата из-за рубежа и стран СНГ
            </Link>
          </div>
        </div>

        <div className="price-table">
          <div className="price-table-title">
            <h3>
              На полгода
              <sup>
                Скидка{' '}
                {100 -
                  Math.floor(
                    (final_prices.month_6 / final_prices.month_3) * 100
                  )}
                %
              </sup>
            </h3>
          </div>
          <div className="price-table-cost">
            <s>{final_prices.month_3}</s> {final_prices.month_6} руб/месяц
            {!!user.discount && (
              <div className="discount">Скидка -50% активирована</div>
            )}
            {!!discount && (
              <div className="discount">Промокод -{discount}% активирован</div>
            )}
          </div>
          <div className="price-table-description">
            <ul>
              <li>
                <strong>Доступ на пол года</strong>
              </li>
              <li>Доступ ко всем рецептам ({RECIPES_COUNT}+)</li>
              <li>Новые рецепты каждый месяц</li>
              <li>Генератор рационов</li>
              <li>Неограниченное кол-во рационов</li>
              <li>Неограниченный шоп-лист</li>
              <li>Неограниченный список избранного</li>
              <li>Синхронизация между устройствами</li>
            </ul>
          </div>
          <div className="price-table-button">
            <button onClick={() => subscriptionHandler(6)}>
              Оплатить {final_prices.month_6 * 6} рублей
            </button>
            <Link to="/ww-payment" className="button button-bordered">
              Оплата из-за рубежа и стран СНГ
            </Link>
          </div>
        </div>

        <div className="price-table">
          <div className="price-table-title">
            <h3>
              На год
              <sup>
                Скидка{' '}
                {100 -
                  Math.floor(
                    (final_prices.month_12 / final_prices.month_3) * 100
                  )}
                %
              </sup>
            </h3>
          </div>
          <div className="price-table-cost">
            <s>{final_prices.month_3}</s> {final_prices.month_12} руб/месяц
            {!!user.discount && (
              <div className="discount">Скидка -50% активирована</div>
            )}
            {!!discount && (
              <div className="discount">Промокод -{discount}% активирован</div>
            )}
          </div>
          <div className="price-table-description">
            <ul>
              <li>
                <strong>Доступ на год</strong>
              </li>
              <li>Доступ ко всем рецептам ({RECIPES_COUNT}+)</li>
              <li>Новые рецепты каждый месяц</li>
              <li>Генератор рационов</li>
              <li>Неограниченное кол-во рационов</li>
              <li>Неограниченный шоп-лист</li>
              <li>Неограниченный список избранного</li>
              <li>Синхронизация между устройствами</li>
            </ul>
          </div>
          <div className="price-table-button">
            <button onClick={() => subscriptionHandler(12)}>
              Оплатить {final_prices.month_12 * 12} рублей
            </button>
            <Link to="/ww-payment" className="button button-bordered">
              Оплата из-за рубежа и стран СНГ
            </Link>
          </div>
        </div>

        <div className="price-table price-table-highlight">
          <div className="price-table-title">
            <h3>Вечный доступ</h3>
          </div>
          <div className="price-table-cost">
            {final_prices.unlimited} руб
            {!!user.discount && (
              <div className="discount">Скидка -50% активирована</div>
            )}
            {!!discount && (
              <div className="discount">Промокод -{discount}% активирован</div>
            )}
          </div>
          <div className="price-table-description">
            <ul>
              <li>
                <strong>Единовременный платеж</strong>
              </li>
              <li>
                <strong>Вечный доступ к сервису</strong>
              </li>
              <li>Доступ ко всем рецептам ({RECIPES_COUNT}+)</li>
              <li>Новые рецепты каждый месяц</li>
              <li>Генератор рационов</li>
              <li>Неограниченное кол-во рационов</li>
              <li>Неограниченный шоп-лист</li>
              <li>Неограниченный список избранного</li>
              <li>Синхронизация между устройствами</li>
            </ul>
          </div>
          <div className="price-table-button">
            <button onClick={() => subscriptionHandler(0)}>
              Оплатить {final_prices.unlimited} рублей
            </button>
            <Link to="/ww-payment" className="button button-bordered">
              Оплата из-за рубежа и стран СНГ
            </Link>
          </div>
        </div>
      </div>
    </PageContent>
  )
}

export default Subscribe
