import LoaderRecipeLine from '../loader/LoaderRecipeLine'
import Message from '../UI/Message'
import PageContent from '../UI/PageWrapper'
import PostCard from '../post/PostCard'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { SITE_URL } from '../../config/main'
import { fetchBlogPosts } from '../utils/http'

const Blog = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['blogposts'],
    queryFn: ({ signal }) => fetchBlogPosts({ signal }),
  })

  let content = ''

  if (isError) {
    content = <Message content={error.info} type="error" />
  }

  if (isLoading) {
    content = <LoaderRecipeLine />
  }

  if (data) {
    content = (
      <div className="blog-posts">
        {data.map((post) => (
          <PostCard
            key={post.id}
            date={post.date}
            title={post.title.rendered}
            image={
              post._embedded.hasOwnProperty('wp:featuredmedia')
                ? post._embedded['wp:featuredmedia'][0].source_url.replace(
                    '.jpg',
                    '-500x500.jpg'
                  )
                : ''
            }
            category={post._embedded['wp:term'][0][0].name}
            link={post.link}
          />
        ))}
      </div>
    )
  }

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Блог</h1>
      </div>
      {content}
    </PageContent>
  )
}

export default Blog
