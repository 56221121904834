import AuthFooter from '../UI/AuthFooter'
import AuthHeaderMenu from '../UI/AuthHeaderMenu'
import Logo from '../UI/Logo'
import Message from '../UI/Message'
import Sidebar from '../UI/Sidebar'
import { Fragment, useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { API_URL, SITE_URL } from '../../config/main'
import { useBodyClass, validateEmail } from '../../helpers'
import { processLogin } from '../../store/auth-actions'

const Login = () => {
  useBodyClass('page-login')

  const dispatch = useDispatch()

  const email_field = useRef()
  const password_field = useRef()

  const [message, setMessage] = useState()

  // Form submit handler
  const formSubmitHandler = (e) => {
    e.preventDefault()

    if (validateEmail(email_field.current.value)) {
      setMessage(null)

      // Process login
      checkLoginDetails({
        username: email_field.current.value,
        password: password_field.current.value,
      })
    } else {
      setMessage({
        type: 'error',
        text: 'Неверно введены email и/или пароль.',
      })
    }
  }

  // Fetch login details
  const checkLoginDetails = useCallback(
    async (payload) => {
      setMessage(null)
      try {
        const response = await fetch(
          API_URL +
            `?act=auth&username=${payload.username}&password=${payload.password}`
        )

        if (!response.ok) {
          throw new Error('Ошибка доступа к API.')
        }

        const data = await response.json()

        if (data.type === 'error') {
          setMessage({ type: 'error', text: data.message })
        }

        if (data.type === 'success') {
          dispatch(processLogin(data.data, data.token))
        }
      } catch (error) {
        setMessage({ type: 'error', text: error.message })
      }
    },
    [dispatch]
  )

  return (
    <Fragment>
      <Sidebar>
        <div className="page-login-wrapper small-width-wrapper">
          <Logo />
          <h1>Добро пожаловать</h1>
          <AuthHeaderMenu />
          {message && <Message content={message.text} type={message.type} />}
          <form onSubmit={formSubmitHandler}>
            <div className="form-input-wrapper">
              <label>Ваш email</label>
              <input type="email" ref={email_field} required />
            </div>
            <div className="form-input-wrapper">
              <label>Ваш пароль</label>
              <input type="password" ref={password_field} required />
            </div>
            <div className="form-link-wrapper">
              <Link to="/forgot-password">Забыли пароль?</Link>
            </div>
            <div className="form-input-wrapper">
              <input type="submit" value="Войти" />
            </div>
          </form>
        </div>
        <div className="auth-details">
          <p>
            Впервые здесь?{' '}
            <a href={SITE_URL} target="_blank" rel="noreferrer">
              Узнайте больше о интерактивной онлайн книге
            </a>{' '}
            и <Link to="/register">Зарегистрируйтесь</Link>.
          </p>
        </div>
        <AuthFooter />
      </Sidebar>
    </Fragment>
  )
}

export default Login
