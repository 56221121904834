import { useState } from 'react'

const StepItem = (props) => {
  const [done, setDone] = useState(false)

  const setDoneHandler = () => {
    setDone((prevState) => {
      return !prevState
    })
  }

  return (
    <div
      className={`step-item-wrapper ${done ? 'done' : ''}`}
      onClick={setDoneHandler}
    >
      <div className="step-item-index">{props.index + 1}</div>
      <div className="step-item-text">{props.text}</div>
    </div>
  )
}

export default StepItem
