import MessageFullwidth from './MessageFullwidth'

const Notification = (props) => {
  const notification = props.notification

  return (
    <MessageFullwidth
      dismissable
      type={notification.type}
      id={notification.id}
      content={notification.message}
      link={notification.link}
    ></MessageFullwidth>
  )
}

export default Notification
