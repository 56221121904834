import { Link } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../helpers'

const TagItem = (props) => {
  if (!props.title) {
    return
  }
  return (
    <div className="tag-item-wrapper">
      <Link
        to={`/recipes/latest/tag/${props.id}`}
        state={{ title: capitalizeFirstLetter(props.title) }}
      >
        #{props.title}
      </Link>
    </div>
  )
}

export default TagItem
