import { useEffect } from 'react'

// Validate email
export const validateEmail = (email) => {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  if (email.match(validRegex)) {
    return true
  } else {
    return false
  }
}

// Validate password
export const validatePassword = (password) => {
  if (password.length > 5) {
    return true
  } else {
    return false
  }
}

// Body css classes
const addBodyClass = (className) => document.body.classList.add(className)
const removeBodyClass = (className) => document.body.classList.remove(className)

export const useBodyClass = (className) => {
  useEffect(() => {
    // Set up
    className instanceof Array
      ? className.map(addBodyClass)
      : addBodyClass(className)

    // Clean up
    return () => {
      className instanceof Array
        ? className.map(removeBodyClass)
        : removeBodyClass(className)
    }
  }, [className])
}

// Capitalize first letter
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// Convert date to DD-MM-YYY
export const convertDateFormat = (d) => {
  const date_fix = d.toString().split('-').join('/')
  const date = new Date(date_fix)

  return date.toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
}

// Days between dates
export const daysBetweenDates = (date_1, date_2) => {
  let difference = new Date(date_1).getTime() - new Date(date_2).getTime()
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24))

  return TotalDays > 30000 ? '∞' : TotalDays
}

// Get week day by index in array
export const getDayByIndex = (index) => {
  const weekdays = []
  weekdays[0] = 'Понедельник'
  weekdays[1] = 'Вторник'
  weekdays[2] = 'Среда'
  weekdays[3] = 'Четверг'
  weekdays[4] = 'Пятница'
  weekdays[5] = 'Суббота'
  weekdays[6] = 'Воскресенье'

  return weekdays[index]
}

// Get eat time by index in array
export const getEatTimeByIndex = (index) => {
  const time = []
  time[0] = 'Завтрак'
  time[1] = 'Обед'
  time[2] = 'Ужин'
  time[3] = 'Перекус'

  return time[index]
}

// Compare objects
export const isObjEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

// Get cookie
export const getCookie = (name) => {
  function escape(s) {
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1')
  }
  var match = document.cookie.match(
    RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)')
  )
  return match ? match[1] : null
}

// Display cooking time
export function displayCookingTime(cooking_time, format = 'full') {
  const hours = Math.floor(cooking_time / 60)
  const minutes = cooking_time % 60
  let displayText = ''

  if (hours > 0) {
    if (format === 'short') {
      displayText += `${hours}`
    } else {
      displayText += `${hours} час${getRussianEnding(hours, ['', 'а', 'ов'])}`
    }
  }

  if (minutes > 0) {
    if (displayText.length > 0) {
      if (format === 'short') {
        displayText += ':'
      } else {
        displayText += ' '
      }
    }
    if (format === 'short') {
      displayText += `${minutes}`
    } else {
      displayText += `${minutes} минут${getRussianEnding(minutes, [
        'а',
        'ы',
        '',
      ])}`
    }
  }

  if (hours > 0 && minutes === 0 && format === 'short') {
    displayText += `:00`
  }

  return displayText
}

function getRussianEnding(number, endings) {
  let cases
  if (number % 10 === 1 && number % 100 !== 11) {
    cases = 0
  } else if (
    number % 10 >= 2 &&
    number % 10 <= 4 &&
    (number % 100 < 10 || number % 100 >= 20)
  ) {
    cases = 1
  } else {
    cases = 2
  }
  return endings[cases]
}
