import 'swiper/css'
import 'swiper/css/pagination'
import AddToRationForm from '../recipe/AddToRationForm'
import DemoModeMessage from '../UI/DemoModeMessage'
import IngredientItem from '../recipe/IngredientItem'
import Links from '../recipe/Links'
import LoaderRecipeSingle from '../loader/LoaderRecipeSingle'
import Message from '../UI/Message'
import ModalDialog from '../UI/ModalDialog'
import PageContent from '../UI/PageWrapper'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import RecipeCategory from '../recipes/RecipeCategory'
import RecipeVideo from '../recipe/RecipeVideo'
import StepItem from '../recipe/StepItem'
import StepItemDone from '../recipe/StepItemDone'
import TagItem from '../recipe/TagItem'
import { motion } from 'framer-motion'
import { MdOndemandVideo } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { displayCookingTime, useBodyClass } from '../../helpers'
import { setUserData } from '../../store/auth-actions'

import {
  API_URL,
  PHOTOS_URL,
  SITE_URL,
  motionTransition,
  motionVariantsSingleRecipe,
} from '../../config/main'

import {
  IoTimerOutline,
  IoTimeOutline,
  IoHeartCircleOutline,
  IoListCircleOutline,
  IoInformationCircleSharp,
  IoPrintOutline,
  IoStorefrontOutline,
  IoCheckmarkCircle,
} from 'react-icons/io5'

const Recipe = React.forwardRef((props, ref) => {
  useBodyClass('page-recipe')

  const swiperRef = useRef()

  const { id } = useParams()

  const dispatch = useDispatch()

  const [recipe, setRecipe] = useState(null)
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [showAddToRationDialog, setShowAddToRationDialog] = useState(false)
  const [isVideoDisplayed, setIsVideoDisplayed] = useState(false)

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  // Fetch recipes
  const fetchRecipe = useCallback(async (payload) => {
    setMessage(null)
    setIsLoading(true)

    try {
      const response = await fetch(
        API_URL +
          `?act=getrecipe&user_id=${payload.user_id}&token=${payload.token}&id=${payload.id}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setIsLoading(false)

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setRecipe(data.data)

        console.log(data)
      }
    } catch (error) {
      setIsLoading(false)
      setMessage({ type: 'error', text: error.message })
    }
  }, [])

  // Add all to shoplist
  const addAllToShoplistHandler = () => {
    if (window.confirm('Добавить ВСЕ ингредиенты рецепта в ваш шоплист?')) {
      addAllToshopListRequest({ user_id: user.id, token: token, recipe_id: id })
    }
  }

  // Add all to shoplist request
  const addAllToshopListRequest = async (payload) => {
    // id
    try {
      const response = await fetch(
        API_URL +
          `?act=addalltoshoplist&user_id=${payload.user_id}&token=${payload.token}`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        }
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      if (data.type === 'error') {
        console.log(data.message)
      }

      if (data.type === 'success') {
        dispatch(
          setUserData({
            ...user,
            shoplist_count: data.data.shoplist_count,
          })
        )
        toast.success('Ингредиенты добавлены в шоплист.')
        // Update ingredients list
        fetchRecipe({
          id: id,
          user_id: user.id,
          token: token,
        })
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  // Add to ration
  const addToRationHandler = () => {
    setShowAddToRationDialog(true)
  }

  const closeModalHandler = () => {
    setShowAddToRationDialog(false)
  }

  const printRecipeHandler = () => {
    props.onPrint()
  }

  // Add to favourites
  const addToFavHandler = () => {
    addToFavRequest({ id: id, user_id: user.id, token })
  }

  // Mark as done
  const markAsDoneHandler = () => {
    markAsDoneRequest({ id: id, user_id: user.id, token })
  }

  const addToFavRequest = async (payload) => {
    // id
    try {
      const response = await fetch(
        API_URL +
          `?act=addtofav&user_id=${payload.user_id}&token=${payload.token}`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        }
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setRecipe({ ...recipe, isFav: data.data })
        toast.success(
          data.data
            ? 'Рецепт добавлен в избранное.'
            : 'Рецепт удален из избранного.'
        )
        dispatch(
          setUserData({
            ...user,
            favourites_count: data.data
              ? user.favourites_count + 1
              : user.favourites_count - 1,
          })
        )
      }
    } catch (error) {
      setMessage({ type: 'error', text: error.message })
    }
  }

  // Mark as done request
  const markAsDoneRequest = async (payload) => {
    // id
    try {
      const response = await fetch(
        API_URL +
          `?act=markasdone&user_id=${payload.user_id}&token=${payload.token}`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        }
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setRecipe({ ...recipe, isDone: data.data })
        toast.success(
          data.data ? 'Рецепт приготовлен.' : 'Рецепт удален из приготовленных.'
        )
        dispatch(
          setUserData({
            ...user,
            done_count: data.data ? user.done_count + 1 : user.done_count - 1,
          })
        )
      }
    } catch (error) {
      setMessage({ type: 'error', text: error.message })
    }
  }

  const addToRationRequest = async (payload) => {
    // id
    try {
      const response = await fetch(
        API_URL +
          `?act=addrecipetoration&user_id=${payload.user_id}&token=${payload.token}`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        }
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        toast.success('Рецепт добавлен в выбранный рацион.')
      }
    } catch (error) {
      setMessage({ type: 'error', text: error.message })
    }
  }

  // Slider settings
  const slider_settings = {
    spaceBetween: 30,
    slidesPerView: 2,
    loop: true,
    onSwiper: (swiper) => {
      swiperRef.current = swiper
    },
    modules: [Pagination],
    pagination: { clickable: true },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      580: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 2,
      },
    },
  }

  // Recipe added to ration
  const addToRationSubmitHandler = (data) => {
    const payload = {
      ...data,
      user_id: user.id,
      token: token,
      recipe_id: id,
    }

    addToRationRequest(payload)
    setShowAddToRationDialog(false)
  }

  // Click to display video
  const onDisplayVideoHandler = () => {
    videoDisplayHandler()
  }

  // Show video
  const videoDisplayHandler = () => {
    setIsVideoDisplayed(true)
  }

  // Close video
  const videoCloseHandler = () => {
    setIsVideoDisplayed(false)
  }

  const getPrintStyle = () => {
    return `@media print {
  .recipe-images, .blog-link, .recipe-actions {
    display: none!important;
  }
}`
  }

  // Use effects
  useEffect(() => {
    fetchRecipe({
      id: id,
      user_id: user.id,
      token: token,
    })
  }, [fetchRecipe, id, token, user.id])

  let content = ''

  if (isLoading) {
    content = (
      <div className="loader-center">
        <LoaderRecipeSingle />
      </div>
    )
  }

  if (recipe && !isLoading) {
    content = (
      <div className="recipe-single-wrapper">
        {isVideoDisplayed && (
          <ModalDialog
            onClose={videoCloseHandler}
            title="Видео рецепт"
            className="recipe-video-modal"
          >
            <RecipeVideo url={recipe.video} />
          </ModalDialog>
        )}
        {showAddToRationDialog && (
          <ModalDialog
            onClose={closeModalHandler}
            title="Добавить рецепт в рацион"
          >
            <AddToRationForm onSubmit={addToRationSubmitHandler} />
          </ModalDialog>
        )}
        <div
          className={`${!recipe.photos[1] ? 'one-image' : ''} recipe-images`}
        >
          <Swiper {...slider_settings}>
            <SwiperSlide>
              <div className="recipe-single-image-wrapper">
                <div
                  className="recipe-single-image"
                  style={{
                    backgroundImage:
                      "url('" + PHOTOS_URL + recipe.photos[0] + "')",
                  }}
                ></div>
              </div>
            </SwiperSlide>
            {recipe.photos[1] && (
              <SwiperSlide>
                <div className="recipe-single-image-wrapper">
                  <div
                    className="recipe-single-image"
                    style={{
                      backgroundImage:
                        "url('" + PHOTOS_URL + recipe.photos[1] + "')",
                    }}
                  ></div>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
        </div>

        <div className="recipe-single-time">
          <div className="recipe-single-time-prepare">
            <IoTimeOutline fontSize="1.2rem" />
            Подготовка: {displayCookingTime(recipe.time_prepare)}
          </div>
          <div className="recipe-single-time-cook">
            <IoTimerOutline fontSize="1.2rem" />
            Приготовление: {displayCookingTime(recipe.time_cook)}
          </div>
        </div>

        <h1 className="recipe-single-title">
          <div className="title">{recipe.title}</div>
          <div className="recipe-actions">
            <div className="recipe-add-to-fav">
              {recipe.isFav ? (
                <IoHeartCircleOutline
                  className="fav"
                  onClick={addToFavHandler}
                  title="Убрать из избранного"
                />
              ) : (
                <IoHeartCircleOutline
                  onClick={addToFavHandler}
                  title="Добавить в избранное"
                />
              )}
            </div>
            <div className="recipe-mark-as-done">
              {recipe.isDone ? (
                <IoCheckmarkCircle
                  className="done"
                  onClick={markAsDoneHandler}
                  title="Убрать из приготовленных"
                />
              ) : (
                <IoCheckmarkCircle
                  onClick={markAsDoneHandler}
                  title="Отметить приготовленным"
                />
              )}
            </div>
            <div className="recipe-add-to-ration">
              <IoListCircleOutline
                onClick={addToRationHandler}
                title="Добавить в рацион"
              />
            </div>
            <div className="recipe-print">
              <IoPrintOutline
                onClick={printRecipeHandler}
                title="Распечатать рецепт"
              />
            </div>
          </div>
        </h1>
        <div className="recipe-categories">
          {recipe.categories.map((item) => (
            <RecipeCategory key={item.id} title={item.title} />
          ))}
        </div>

        <div className="recipe-single-ingredients">
          <h3>
            <span>Ингредиенты</span>
            <a
              href={`${SITE_URL}ingredients`}
              className="blog-link"
              target="_blank"
              rel="noreferrer"
            >
              <IoInformationCircleSharp />
              <span>Советы по ингредиентам</span>
            </a>
          </h3>
          <span
            className="button button-small button-fixed button-bordered margin-bottom"
            onClick={addAllToShoplistHandler}
          >
            <IoStorefrontOutline />
            Добавить все в шоплист
          </span>
          {recipe.ingredients === '-' && <DemoModeMessage />}
          {recipe.ingredients !== '-' &&
            recipe.ingredients.map((item, index) => (
              <IngredientItem
                added={recipe.shoplist.includes(item)}
                title={item}
                key={index}
              />
            ))}
          {recipe.links.length > 0 && <Links links={recipe.links} />}
        </div>

        <div className="recipe-single-steps">
          <h3>Приготовление</h3>
          {recipe.video && (
            <span
              className="button button-small button-fixed button-bordered margin-bottom"
              onClick={onDisplayVideoHandler}
            >
              <MdOndemandVideo />
              Видео рецепт
            </span>
          )}

          {recipe.steps === '-' && <DemoModeMessage />}
          {recipe.steps !== '-' &&
            recipe.steps.map((item, index) =>
              item ? <StepItem text={item} key={index} index={index} /> : ''
            )}

          {recipe.steps !== '-' && !recipe.isDone && (
            <StepItemDone markRecipeAsDone={markAsDoneHandler} />
          )}
        </div>
        <div className="recipe-single-details">
          {recipe.servings && (
            <div className="recipe-single-serving">
              <h4>Подача</h4>
              {recipe.servings === '-' ? (
                <DemoModeMessage />
              ) : (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: recipe.servings }}
                ></div>
              )}
            </div>
          )}
          {recipe.notes && (
            <div className="recipe-single-notes">
              <h4>Заметки</h4>

              {recipe.notes === '-' ? (
                <DemoModeMessage />
              ) : (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: recipe.notes }}
                ></div>
              )}
            </div>
          )}
        </div>
        <div className="tags-items-wrapper">
          {recipe.tags.map((tag, index) => (
            <TagItem title={tag.title} key={tag.id} id={tag.id} />
          ))}
        </div>
      </div>
    )
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={motionVariantsSingleRecipe}
      transition={motionTransition}
      ref={ref}
    >
      <style>{getPrintStyle()}</style>
      <PageContent>
        {message && <Message content={message.text} type={message.type} />}
        {content}
      </PageContent>
    </motion.div>
  )
})

export default Recipe
