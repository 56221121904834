import LoaderRecipeLine from '../loader/LoaderRecipeLine'
import Message from '../UI/Message'
import PageContent from '../UI/PageWrapper'
import RecipeCard from '../recipes/RecipeCard'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { API_URL } from '../../config/main'
import { useBodyClass } from '../../helpers'

const SearchResults = () => {
  useBodyClass('page-search')

  const [recipes, setRecipes] = useState([])
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const { query } = useParams()

  // Fetch recipes
  const fetchRecipesList = useCallback(async (payload) => {
    setMessage(null)
    setIsLoading(true)

    try {
      const response = await fetch(
        API_URL +
          `?act=search&user_id=${payload.user_id}&token=${payload.token}&query=${payload.query}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      console.log(data)

      setIsLoading(false)

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setRecipes(data.data)
      }
    } catch (error) {
      setIsLoading(false)
      setMessage({ type: 'error', text: error.message })
    }
  }, [])

  // Use effects
  useEffect(() => {
    fetchRecipesList({
      query: query,
      user_id: user.id,
      token: token,
    })
  }, [fetchRecipesList, query, user.id, token])

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">
          <Link to="/recipes/">Рецепты</Link> / Результаты поиска "{query}"
        </h1>
      </div>
      <div className="page-section page-section-list">
        <div className="section-title">
          <h2>Найдено {recipes.length} рецептов</h2>
        </div>
        {message && <Message content={message.text} type={message.type} />}
        {recipes.length === 0 && (
          <div>
            <p>
              По вашему запросу ничего не найдено. Попробуйте вводить часть
              слова (ингредиента или названия), к примеру "мук" вместо "мука",
              так как это слово может быть в рецепте в другом склонении (100г
              мук<b>и</b>
              ).
            </p>
            <Link to="/recipes/">Попробуйте еще раз.</Link>
          </div>
        )}
        <div className="recipes-list">
          {isLoading && <LoaderRecipeLine />}
          {recipes.map((recipe) => (
            <RecipeCard
              type="line"
              key={recipe.id}
              title={recipe.title}
              id={recipe.id}
              image={recipe.photos}
              categories={recipe.categories}
              time_prepare={recipe.time_prepare}
              time_cook={recipe.time_cook}
              video={recipe.video}
            />
          ))}
        </div>
      </div>
    </PageContent>
  )
}

export default SearchResults
