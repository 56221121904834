import 'swiper/css'
import LoaderRecipeCard from '../loader/LoaderRecipeCard'
import Message from '../UI/Message'
import RecipeCard from './RecipeCard'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { recipes_slider_settings } from '../../config/swiperslider'
import { fetchRecipesList } from '../utils/http'

const RecipeSection = (props) => {
  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const [swiper, setSwiper] = useState(null)

  const payload = user
    ? {
        type: props.type,
        tag: props.tag ? props.tag : 0,
        limit: 10,
        user_id: user.id,
        token: token,
      }
    : null

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [
      'recipeslist',
      { type: payload.type, tag: props.tag, limit: payload.limit },
    ],
    queryFn: ({ signal }) => fetchRecipesList({ signal, payload }),
  })

  const nextSlideHandler = () => {
    swiper?.slideNext()
  }

  const prevSlideHandler = () => {
    swiper?.slidePrev()
  }

  const recipesLink = props.tag
    ? `${props.type}/tag/${props.tag}`
    : `${props.type}`

  let content = ''

  if (isError) {
    content = <Message content={error.info?.message} type="error" />
  }

  if (isLoading) {
    content = <LoaderRecipeCard />
  }

  if (data) {
    content = (
      <div className="recipes-list">
        <Swiper onSwiper={setSwiper} {...recipes_slider_settings}>
          {data.data?.map((recipe) => (
            <SwiperSlide key={recipe.id}>
              <RecipeCard
                key={recipe.id}
                title={recipe.title}
                id={recipe.id}
                image={recipe.photos}
                categories={recipe.categories}
                time_prepare={recipe.time_prepare}
                time_cook={recipe.time_cook}
                video={recipe.video}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  }

  return (
    <div className="page-section">
      <div className="section-title">
        <h2>{props.title}</h2>
        <div className="slider-nav">
          <div
            className="nav-button nav-button-prev"
            onClick={prevSlideHandler}
          >
            <MdKeyboardArrowLeft fontSize="1.4rem" />
          </div>
          <div
            className="nav-button nav-button-next"
            onClick={nextSlideHandler}
          >
            <MdKeyboardArrowRight fontSize="1.4rem" />
          </div>
          <Link
            className="nav-button nav-button-wide"
            to={recipesLink}
            state={{ title: props.title }}
          >
            Все ({data?.total || 0})
          </Link>
        </div>
      </div>
      {content}
    </div>
  )
}

export default RecipeSection
