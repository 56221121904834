import LoaderRecipeLine from '../loader/LoaderRecipeLine'
import Message from '../UI/Message'
import PageContent from '../UI/PageWrapper'
import RationDay from '../ration/RationDay'
import RecipeCard from '../recipes/RecipeCard'
import { useCallback, useEffect, useState } from 'react'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API_URL } from '../../config/main'

import {
  useBodyClass,
  getEatTimeByIndex,
  convertDateFormat,
} from '../../helpers'

const Ration = (props) => {
  useBodyClass('page-ration')

  const navigate = useNavigate()

  const [message, setMessage] = useState()
  const [ration, setRation] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const { id } = useParams()

  // Fetch ration data
  const fetchRation = useCallback(
    async (payload) => {
      setMessage(null)
      setIsLoading(true)

      try {
        const response = await fetch(
          API_URL +
            `?act=getration&user_id=${payload.user_id}&token=${payload.token}&id=${payload.id}`
        )

        if (!response.ok) {
          throw new Error('Ошибка доступа к API.')
        }

        const data = await response.json()

        setIsLoading(false)

        if (data.type === 'error') {
          setMessage({ type: 'error', text: data.message })
          navigate('/ration')
        }

        if (data.type === 'success') {
          if (data.data.data.length > 7) {
            data.data.data.pop() // bug fix for broken rations with last broken day in db
          }
          setRation(data.data)
        }
      } catch (error) {
        setIsLoading(false)
        setMessage({ type: 'error', text: error.message })
      }
    },
    [navigate]
  )

  // Fetch ration data
  const removeRation = useCallback(
    async (payload) => {
      setMessage(null)

      try {
        const response = await fetch(
          API_URL +
            `?act=removeration&user_id=${payload.user_id}&token=${payload.token}&id=${payload.id}`
        )

        if (!response.ok) {
          throw new Error('Ошибка доступа к API.')
        }

        const data = await response.json()

        if (data.type === 'error') {
          setMessage({ type: 'error', text: data.message })
        }

        if (data.type === 'success') {
          toast.success('Рацион "' + payload.title + '" удален')

          navigate('/ration/')
        }
      } catch (error) {
        setMessage({ type: 'error', text: error.message })
      }
    },
    [navigate]
  )

  // Remove ration
  const removeRationHandler = () => {
    if (window.confirm('Удалить рацион?')) {
      removeRation({
        id: id,
        user_id: user.id,
        token: token,
        title: ration.title,
      })
    }
  }

  // Use effects
  useEffect(() => {
    fetchRation({
      id: id,
      user_id: user.id,
      token: token,
    })
  }, [fetchRation, token, user.id, id])

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Рацион</h1>
        <div className="button-remove" onClick={removeRationHandler}>
          <IoCloseCircleOutline />
        </div>
      </div>
      <div className="page-section page-section-list">
        <div className="section-title">
          <h2>
            {ration ? ration.title : '...'}
            <div className="ration-date">
              Создан {ration ? convertDateFormat(ration.date) : '...'}
            </div>
          </h2>
        </div>
        {message && <Message content={message.text} type={message.type} />}
        <div className="recipes-list">
          {isLoading && <LoaderRecipeLine />}
          {ration &&
            ration.data.length > 0 &&
            ration.data.map((day, index) => (
              <RationDay
                index={index}
                key={index + Math.floor(Math.random() * 1000)}
              >
                {day.map(
                  (recipe, r_index) =>
                    recipe && (
                      <RecipeCard
                        type="line"
                        key={recipe.id + Math.floor(Math.random() * 1000)}
                        title={recipe.title}
                        id={recipe.id}
                        image={recipe.photos}
                        categories={[
                          { id: r_index, title: getEatTimeByIndex(r_index) },
                        ]}
                        time_prepare={recipe.time_prepare}
                        time_cook={recipe.time_cook}
                        video={recipe.video}
                      />
                    )
                )}
              </RationDay>
            ))}
        </div>
      </div>
    </PageContent>
  )
}

export default Ration
