import { Link } from 'react-router-dom'

import { IoArrowForwardCircleOutline, IoCalendarOutline } from 'react-icons/io5'
import { convertDateFormat } from '../../helpers'

const RationItem = (props) => {
  const item = props.item
  return (
    <div className="ration-item">
      <Link to={`/ration/${item.id}`}>
        <div className="ration-item-list-icon">
          <IoCalendarOutline />
        </div>
        <div className="ration-item-title">
          {item.title}
          <div className="ration-item-date">{convertDateFormat(item.date)}</div>
        </div>
        <div className="ration-item-icon">
          <IoArrowForwardCircleOutline />
        </div>
      </Link>
    </div>
  )
}

export default RationItem
