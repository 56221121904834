import AuthFooter from '../UI/AuthFooter'
import AuthHeaderMenu from '../UI/AuthHeaderMenu'
import Logo from '../UI/Logo'
import Message from '../UI/Message'
import Sidebar from '../UI/Sidebar'
import { Fragment, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { API_URL } from '../../config/main'
import { useBodyClass, validateEmail } from '../../helpers'

const ForgotPassword = () => {
  useBodyClass('page-forgot-password')

  const emailField = useRef()
  const [message, setMessage] = useState()
  const [isSent, setIsSent] = useState(false)

  const formSubmitHandler = (e) => {
    e.preventDefault()

    if (validateEmail(emailField.current.value)) {
      sendForgotPasswordRequest({ email: emailField.current.value })
    } else {
      setMessage({ type: 'error', text: 'Введен неверный email.' })
    }
  }

  // Create new ration request
  const sendForgotPasswordRequest = async (payload) => {
    // id
    try {
      const response = await fetch(
        API_URL + `?act=forgotpassword&email=${payload.email}`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        }
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setMessage(false)
        setIsSent(true)
      }
    } catch (error) {
      setMessage({ type: 'error', text: error.message })
    }
  }

  return (
    <Fragment>
      <Sidebar>
        <div className="page-login-wrapper small-width-wrapper">
          <Logo />
          <h1>Забыли пароль?</h1>
          <AuthHeaderMenu />
          {message && <Message content={message.text} type={message.type} />}
          {isSent ? (
            <div>
              <p>Новый временный пароль отправлен на ваш email.</p>
              <p>
                {' '}
                Если вы не получили его в течении нескольких минут свяжитесь с
                нами -{' '}
                <a href="mailto:sales@miukagirl.ru">support@mirecipes.ru</a>.
              </p>
              <p>
                <Link to="/login" className="button">
                  Войти с новым паролем
                </Link>
              </p>
            </div>
          ) : (
            <form onSubmit={formSubmitHandler}>
              <div className="form-input-wrapper">
                <label>Ваш email</label>
                <input
                  type="text"
                  placeholder="email@gmail.com"
                  ref={emailField}
                  required
                />
              </div>
              <div className="form-input-wrapper">
                <input type="submit" value="Восстановить пароль" />
              </div>
            </form>
          )}
        </div>
        <AuthFooter />
      </Sidebar>
    </Fragment>
  )
}

export default ForgotPassword
