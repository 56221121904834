import Message from '../UI/Message'
import PageContent from '../UI/PageWrapper'
import ReactInputMask from 'react-input-mask'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { API_URL, SUBSCRIPTION_ENABLED, VERSION } from '../../config/main'
import { setUserData } from '../../store/auth-actions'

import {
  useBodyClass,
  convertDateFormat,
  daysBetweenDates,
  validateEmail,
  validatePassword,
} from '../../helpers'

const Account = () => {
  useBodyClass('page-account')

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const [edit, setEdit] = useState(false)
  const [message, setMessage] = useState()

  const field_name = useRef()
  const field_email = useRef()
  const field_password = useRef()
  const field_phone = useRef()

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const subscribeHandler = () => {
    navigate('/subscribe', { replace: false })
  }

  const unsubscribeHandler = () => {
    if (
      window.confirm(
        'Автопродление подписки будет отменено, ваш оплаченный период сохранится. Вы сможете продлить подписку еще раз в любой момент. Продолжить?'
      )
    ) {
      // Request to cancel subscription. Remove on server
      requestCancelSubscription({
        user_id: user.id,
        token: token,
      })
    }
  }

  const formSubmitHandler = (e) => {
    e.preventDefault()

    if (
      field_name.current.value !== '' &&
      field_email.current.value !== '' &&
      field_phone.current.value !== '' &&
      validateEmail(field_email.current.value)
    ) {
      if (
        field_password.current.value !== '' &&
        !validatePassword(field_password.current.value)
      ) {
        setMessage({
          type: 'error',
          text: 'Пароль должен быть не менее 5 символов.',
        })
      } else {
        processUpdateUserData({
          user_id: user.id,
          token: token,
          name: field_name.current.value,
          email: field_email.current.value,
          password: field_password.current.value,
          phone: field_phone.current.value,
        })
      }
    } else {
      setMessage({
        type: 'error',
        text: 'Пожалуйста верно заполните все поля.',
      })
    }
  }

  const refreshAppHandler = () => {
    window.location.reload(true)
  }

  const onEditHandler = () => {
    setEdit(true)
  }

  const processUpdateUserData = useCallback(
    async (payload) => {
      setMessage(null)

      try {
        const response = await fetch(
          API_URL +
            `?act=updateuserdata&user_id=${payload.user_id}&token=${payload.token}`,
          {
            method: 'POST',
            body: JSON.stringify(payload),
          }
        )

        if (!response.ok) {
          throw new Error('Ошибка доступа к API.')
        }

        const data = await response.json()

        if (data.type === 'error') {
          setMessage({ type: 'error', text: data.message })
        }

        if (data.type === 'success') {
          setMessage({ type: 'success', text: 'Изменения сохранены.' })
          dispatch(setUserData(data.data))
          setEdit(false)
        }
      } catch (error) {
        setMessage({ type: 'error', text: error.message })
      }
    },
    [dispatch]
  )

  const requestCancelSubscription = useCallback(async (payload) => {
    try {
      const response = await fetch(
        API_URL +
          `?act=cancelsubscription&user_id=${payload.user_id}&token=${payload.token}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        toast.success('Подписка отменена.')
      }
    } catch (error) {
      setMessage({ type: 'error', text: error.message })
    }
  }, [])

  const deleteAccountLink =
    'mailto:support@mirecipes.ru?subject=Удалить%20аккаунт&body=Я%20подтверждаю%20мое%20согласие%20на%20удаление%20аккаунта%20' +
    user.email +
    '%20и%20осознаю%20что%20это%20действие%20необратимо.'

  useEffect(() => {
    if (location.search === '?processing') {
      setMessage({
        type: 'success',
        text: 'Ваш платеж обрабатывается. Оплаченный период добавится в течении минуты.',
      })
    }
  }, [location.search])

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Мой аккаунт</h1>
      </div>
      {message && <Message content={message.text} type={message.type} />}
      <div className="page-section page-section-list">
        <div className="section-title">
          <h2>Мои достижения</h2>
        </div>
        <div className="page-section-details-wrapper">
          <div className="page-section-details-row">
            <div className="page-section-details-label">
              Приготовлено рецептов
            </div>
            <div className="page-section-details-content">
              <Link to="/recipes/done">{user.done_count}</Link>
            </div>
          </div>
          <div className="page-section-details-row">
            <div className="page-section-details-label">Дней в системе</div>
            <div className="page-section-details-content">
              {daysBetweenDates(new Date(), user.registered)} дней
            </div>
          </div>
        </div>
      </div>
      <div className="page-section page-section-list">
        <div className="section-title">
          <h2>Мой тариф</h2>
        </div>
        <div className="page-section-details-wrapper">
          <div className="page-section-details-row">
            <div className="page-section-details-label">Доступ к сервису</div>
            <div className="page-section-details-content">
              {user.active ? (
                <span className="active">
                  Оплачен (осталось{' '}
                  {daysBetweenDates(user.active_by, new Date())} дней)
                </span>
              ) : (
                <span className="not-active">Неоплачен</span>
              )}
            </div>
          </div>
          {SUBSCRIPTION_ENABLED ? (
            <div className="page-section-details-row">
              <div className="page-section-details-label">Статус подписки</div>
              <div className="page-section-details-content">
                {user.active_subscription ? (
                  <span className="active">Активна</span>
                ) : (
                  <span className="not-active">Не выбрана</span>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          {user.active ? (
            <Fragment>
              <div className="page-section-details-row">
                <div className="page-section-details-label">
                  Следующая оплата
                </div>
                <div className="page-section-details-content">
                  {daysBetweenDates(user.active_by, new Date()) === '∞'
                    ? 'Никогда (Вечный тариф)'
                    : convertDateFormat(user.active_by)}
                </div>
              </div>
            </Fragment>
          ) : (
            ''
          )}
          <div className="buttons-wrapper">
            {daysBetweenDates(user.active_by, new Date()) !== '∞' ? (
              <button
                className="button-fixed button-accent"
                onClick={subscribeHandler}
              >
                {user.active_subscription
                  ? 'Изменить/Продлить тариф'
                  : 'Выбрать тариф'}
              </button>
            ) : (
              ''
            )}

            {SUBSCRIPTION_ENABLED &&
            user.active_subscription &&
            daysBetweenDates(user.active_by, new Date()) !== '∞' ? (
              <button
                className="button button-fixed button-destroy"
                onClick={unsubscribeHandler}
              >
                Отменить подписку
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <div className="page-section page-section-list">
        <div className="section-title">
          <h2>Мои данные</h2>
        </div>
        <div className="page-section-details-wrapper">
          <div className="page-section-details-row">
            <div className="page-section-details-label">Email/логин (#ID)</div>
            <div className="page-section-details-content">
              {user.email} (#{user.id})
            </div>
          </div>
          <div className="page-section-details-row">
            <div className="page-section-details-label">Имя</div>
            <div className="page-section-details-content">{user.name}</div>
          </div>
          <div className="page-section-details-row">
            <div className="page-section-details-label">Телефон</div>
            <div className="page-section-details-content">{user.phone}</div>
          </div>
          <div className="page-section-details-row">
            <div className="page-section-details-label">Дата регистрации</div>
            <div className="page-section-details-content">
              {convertDateFormat(user.registered)}
            </div>
          </div>
        </div>

        {!edit && (
          <div className="buttons-wrapper">
            <button className="button button-fixed" onClick={onEditHandler}>
              Изменить данные
            </button>
          </div>
        )}

        <div className={`form-edit-wrapper ${edit ? 'display' : 'hide'}`}>
          <div className="section-title">
            <h2>Изменить данные</h2>
          </div>
          <div className="form-wrapper">
            <form onSubmit={formSubmitHandler}>
              <div className="form-input-wrapper">
                <label>Ваше имя</label>
                <input type="text" defaultValue={user.name} ref={field_name} />
              </div>
              <div className="form-input-wrapper">
                <label>Ваш email</label>
                <input
                  type="email"
                  defaultValue={user.email}
                  ref={field_email}
                />
                <span className="form-description">
                  Изменение email изменит ваш логин для входа.
                </span>
              </div>
              <div className="form-input-wrapper">
                <label>Ваш телефон</label>
                <ReactInputMask
                  defaultValue={user.phone}
                  mask="+9 (999) 999-99-99"
                  ref={field_phone}
                />
              </div>
              <div className="form-input-wrapper">
                <label>Изменить пароль</label>
                <input type="password" ref={field_password} />
                <span className="form-description">
                  Оставьте поле пустым чтобы не менять.
                </span>
              </div>
              <div className="form-input-wrapper">
                <input
                  type="submit"
                  className="button button-fixed"
                  value="Сохранить изменения"
                />
              </div>
            </form>
          </div>
        </div>

        <h2>Решение проблем</h2>
        <p>
          Если вы не видите новый функционал приложения после обновления, или
          что-то работает не так как нужно попробуйте нажать кнопку ниже для
          принудительного обновления приложения.
        </p>

        <div className="form-wrapper">
          <div className="form-input-wrapper">
            <p>
              <input
                type="button"
                className="button button-fixed"
                value="Обновить приложение"
                onClick={refreshAppHandler}
              />
            </p>
          </div>
        </div>

        <p>
          Если проблема сохраняется пожалуйста свяжитесь с нами по email{' '}
          <a href="mailto:support@mirecipes.ru">support@mirecipes.ru</a>
        </p>
        <p>
          <strong>Текущая версия приложения:</strong> {VERSION}
        </p>
        <p>
          <strong>Браузер:</strong> {navigator.userAgent}
        </p>
        <h2>Удаление аккаунта</h2>
        <div className="form-wrapper">
          <div className="form-input-wrapper">
            <p>
              <a
                className="button button-fixed button-destroy"
                href={deleteAccountLink}
              >
                Удалить аккаунт
              </a>
            </p>
            <p>
              Позволяет полностью удалить ваш аккаунт из системы, если вы не
              планируете ее использовать и не хотите больше получать
              информационные письма.
            </p>
          </div>
        </div>
      </div>
    </PageContent>
  )
}

export default Account
