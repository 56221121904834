import PageContent from '../UI/PageWrapper'
import RecipeSectionSlider from '../recipes/RecipeSectionSlider'
import SearchBox from '../UI/SearchBox'
import TagsSectionSlider from '../tags/TagsSectionSlider'
import { useState } from 'react'
import { IoRefresh } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useBodyClass } from '../../helpers'
import { queryClient } from '../utils/http'

const Recipes = () => {
  useBodyClass('page-recipes')

  const user = useSelector((state) => state.auth.user)

  const [refreshKey, setRefreshKey] = useState(0)
  const navigate = useNavigate()

  const onSearchSubmitHandler = (query) => {
    navigate(`/search/${query}`)
  }

  const refreshHandler = () => {
    queryClient.invalidateQueries({
      queryKey: ['recipeslist'],
    })

    queryClient.invalidateQueries({
      queryKey: ['tagslist'],
    })

    setRefreshKey((key) => key + 1)
  }

  return (
    <PageContent key={refreshKey}>
      <div className="page-title-wrapper">
        <h1 className="page-title">
          <span>Рецепты</span>
          <div
            className="refresh-button"
            onClick={refreshHandler}
            title="Обновить рецепты"
          >
            <IoRefresh />
          </div>
        </h1>
        <SearchBox onSearchSubmit={onSearchSubmitHandler} />
      </div>
      <TagsSectionSlider title="По тегам" />
      {!user.active ? (
        <RecipeSectionSlider title="Демо рецепты" type="demo" />
      ) : (
        ''
      )}
      <RecipeSectionSlider title="Приготовленные вами" type="done" />

      <RecipeSectionSlider title="Последние" type="latest" />
      <RecipeSectionSlider title="Популярные" type="popular" />

      <RecipeSectionSlider title="На завтрак" type="breakfast" />
      <RecipeSectionSlider title="На обед" type="lunch" />
      <RecipeSectionSlider title="На ужин" type="dinner" />
      <RecipeSectionSlider title="Перекус" type="meal" />

      <RecipeSectionSlider title="Быстро и просто" type="fast" />

      <RecipeSectionSlider title="Супы" type="latest" tag="5" />
      <RecipeSectionSlider
        title="Без сахара, глютена и молока"
        type="nosugargluten"
      />
      <RecipeSectionSlider title="Белок" type="latest" tag="31" />
      <RecipeSectionSlider title="Без яиц" type="latest" tag="30" />
      <RecipeSectionSlider title="Ланчбокс" type="latest" tag="4" />
      <RecipeSectionSlider title="Печенье" type="latest" tag="8" />
      <RecipeSectionSlider title="Блины и оладьи" type="pancakes" />
      <RecipeSectionSlider title="Вафли" type="latest" tag="13" />
      <RecipeSectionSlider title="Железо" type="latest" tag="29" />
      <RecipeSectionSlider title="Кишечник" type="latest" tag="39" />
      <RecipeSectionSlider title="Мясо" type="latest" tag="20" />
      <RecipeSectionSlider title="На праздник" type="celebrate" />
    </PageContent>
  )
}

export default Recipes
