import { NavLink } from 'react-router-dom'

const AuthHeaderMenu = () => {
  return (
    <div className="login-menu">
      <NavLink
        to="/login"
        className={(navData) => {
          return navData.isActive ? 'active' : ''
        }}
      >
        Войти
      </NavLink>
      <NavLink
        to="/register"
        className={(navData) => {
          return navData.isActive ? 'active' : ''
        }}
      >
        Зарегистрироваться
      </NavLink>
    </div>
  )
}

export default AuthHeaderMenu
