import { Link } from 'react-router-dom'
import PageContent from '../UI/PageWrapper'

const PaymentProblem = () => {
  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Проблема с оплатой</h1>
      </div>
      <div className="page-description">
        <p>
          В процессе оплаты возникла техническая ошибка или вы отменили платеж.
          Попробуйте оплатить еще раз. Если проблема сохраняется пожалуйста
          свяжитесь с нами{' '}
          <a href="mailto:support@mirecipes.ru">support@mirecipes.ru</a>.
        </p>
        <p>
          <strong>ВАЖНО:</strong> Если вы оплачиваете картой банка выпущенной в{' '}
          <strong>странах СНГ или за рубежом</strong>{' '}
          <Link to="/ww-payment">
            воспользуйтесь этой инструкцией по оплате через Boosty
          </Link>
          .
          <p>
            Стандартная онлайн оплата через сервис ЮКассы возможна{' '}
            <strong>только картами российских банков.</strong>
          </p>
        </p>
        <p>
          <Link to="/subscribe">Попробовать еще раз</Link>
        </p>
      </div>
    </PageContent>
  )
}

export default PaymentProblem
