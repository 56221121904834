// Tags Slider settings
export const tags_slider_settings = {
  spaceBetween: 8,
  slidesPerView: 7,
  loop: false,
  breakpoints: {
    0: {
      slidesPerView: 2,
    },
    400: {
      slidesPerView: 3,
    },
    580: {
      slidesPerView: 4,
    },
    768: {
      slidesPerView: 5,
    },
    1024: {
      slidesPerView: 8,
    },
  },
}

// Recipes Slider settings
export const recipes_slider_settings = {
  spaceBetween: 30,
  slidesPerView: 3,
  loop: false,
  pagination: { clickable: true },
  breakpoints: {
    0: {
      slidesPerView: 1,
    },
    581: {
      slidesPerView: 2,
    },
    769: {
      slidesPerView: 3,
    },
    1025: {
      slidesPerView: 3,
    },
  },
}
