import LoaderRecipeLine from '../loader/LoaderRecipeLine'
import Message from '../UI/Message'
import PageContent from '../UI/PageWrapper'
import RationItem from '../ration/RationItem'
import { useCallback, useEffect, useRef, useState } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { API_URL } from '../../config/main'
import { useBodyClass } from '../../helpers'

const RationList = () => {
  useBodyClass('page-ration-list')

  const [message, setMessage] = useState()
  const [rationList, setRationsList] = useState([])
  const [showRationForm, setShowRationForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [emptyRation, setEmptyRation] = useState(false)

  const field_title = useRef()
  const field_norepeat = useRef()
  const field_nobreakfast = useRef()
  const field_nolanch = useRef()
  const field_nodinner = useRef()
  const field_nomeal = useRef()
  const field_empty = useRef()

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const emptyRationHandler = () => {
    setEmptyRation(field_empty.current.checked)
  }

  const addRationHandler = () => {
    setShowRationForm(true)
  }

  const cancelRationHandler = (e) => {
    setShowRationForm(false)
    setEmptyRation(false)
  }

  const createRationHandler = (e) => {
    e.preventDefault()

    createRationRequest({
      title: field_title.current.value,
      norepeat: field_norepeat.current.checked,
      nobreakfast: field_nobreakfast.current.checked,
      nolanch: field_nolanch.current.checked,
      nodinner: field_nodinner.current.checked,
      nomeal: field_nomeal.current.checked,
      empty: field_empty.current.checked,
      user_id: user.id,
      token,
    })

    setEmptyRation(false)
  }

  // Create new ration request
  const createRationRequest = async (payload) => {
    // id
    try {
      const response = await fetch(
        API_URL +
          `?act=createration&user_id=${payload.user_id}&token=${payload.token}`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        }
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        if (rationList) {
          setRationsList([
            { title: payload.title, date: new Date(), id: data.id },
            ...rationList,
          ])
        } else {
          setRationsList([
            { title: payload.title, date: new Date(), id: data.id },
          ])
        }

        setMessage(false)
        toast.success('Новый рацион добавлен')
        setShowRationForm(false)
      }
    } catch (error) {
      setMessage({ type: 'error', text: error.message })
    }
  }

  // Fetch rations list
  const fetchRationsList = useCallback(async (payload) => {
    setMessage(null)
    setIsLoading(true)

    try {
      const response = await fetch(
        API_URL +
          `?act=getrationslist&user_id=${payload.user_id}&token=${payload.token}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setIsLoading(false)

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setRationsList(data.data)
      }
    } catch (error) {
      setIsLoading(false)
      setMessage({ type: 'error', text: error.message })
    }
  }, [])

  // Use effects
  useEffect(() => {
    fetchRationsList({
      user_id: user.id,
      token: token,
    })
  }, [fetchRationsList, token, user.id])

  if (!user.active) {
    return (
      <PageContent>
        <div className="page-title-wrapper">
          <h1 className="page-title">Генератор рациона</h1>
        </div>
        <div className="page-description">
          Генератор рациона доступен только при активном тарифе. Генератор
          рациона позволяет автоматически создать неограниченное количество
          недельных рационов (подборок рецептов) на каждый день для каждого
          приема пищи (завтрак, обед, ужин и перекус). Также вы можете вручную
          заменить любые рецепты в любом рационе.
        </div>
      </PageContent>
    )
  }

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Генератор рациона</h1>
        {!showRationForm && (
          <button
            className="button-fixed button-with-icon"
            onClick={addRationHandler}
          >
            <IoAddCircleOutline />
            <span>Новый рацион</span>
          </button>
        )}
      </div>
      {message && <Message content={message.text} type={message.type} />}
      {showRationForm && (
        <div className="ration-form-wrapper">
          <form onSubmit={createRationHandler}>
            <div>
              <div className="form-input-wrapper">
                <label>Название рациона</label>
                <input
                  type="text"
                  placeholder="Неделя 1"
                  ref={field_title}
                  required
                />
              </div>

              <div className="form-input-wrapper">
                <p className="form-description">
                  Хотите сами подбирать рецепты:
                </p>
                <input
                  type="checkbox"
                  className="checkbox checkbox_squared"
                  ref={field_empty}
                  defaultChecked={emptyRation}
                  defaultValue="1"
                  id="field_empty"
                  onClick={emptyRationHandler}
                />
                <label htmlFor="field_empty">Создать пустой рацион</label>
              </div>

              <div className={emptyRation ? 'hide' : ''}>
                <div className="form-input-wrapper">
                  <p className="form-description">Все рецепты будут разные:</p>
                  <input
                    type="checkbox"
                    className="checkbox checkbox_squared"
                    ref={field_norepeat}
                    defaultChecked={true}
                    defaultValue="1"
                    id="field_norepeat"
                  />
                  <label htmlFor="field_norepeat">Не повторять рецепты</label>
                </div>
                <div className="form-input-wrapper">
                  <p className="form-description">Нужны не все приемы пищи:</p>
                  <input
                    type="checkbox"
                    className="checkbox checkbox_squared"
                    ref={field_nobreakfast}
                    defaultChecked={false}
                    defaultValue="1"
                    id="field_nobreakfast"
                  />
                  <label htmlFor="field_nobreakfast">
                    Не добавлять завтраки
                  </label>
                </div>
                <div className="form-input-wrapper">
                  <input
                    type="checkbox"
                    className="checkbox checkbox_squared"
                    ref={field_nolanch}
                    defaultChecked={false}
                    defaultValue="1"
                    id="field_nolanch"
                  />
                  <label htmlFor="field_nolanch">Не добавлять обеды</label>
                </div>
                <div className="form-input-wrapper">
                  <input
                    type="checkbox"
                    className="checkbox checkbox_squared"
                    ref={field_nodinner}
                    defaultChecked={false}
                    defaultValue="1"
                    id="field_nodinner"
                  />
                  <label htmlFor="field_nodinner">Не добавлять ужины</label>
                </div>
                <div className="form-input-wrapper">
                  <input
                    type="checkbox"
                    className="checkbox checkbox_squared"
                    ref={field_nomeal}
                    defaultChecked={false}
                    defaultValue="1"
                    id="field_nomeal"
                  />
                  <label htmlFor="field_nomeal">Не добавлять перекусы</label>
                </div>
              </div>
              <div className="buttons-wrapper">
                <button onClick={addRationHandler}>Создать рацион</button>
                <button onClick={cancelRationHandler} className="button-cancel">
                  Отмена
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {isLoading && <LoaderRecipeLine />}
      {!isLoading && !rationList && (
        <div className="page-description">
          <p>
            Генератор рациона позволяет автоматически подобрать рецепты на
            неделю на каждый прием питания (Завтрак, Обед, Ужин и Перекус).
          </p>
          <p>
            Вы можете создать неограниченное количество рационов с разными
            параметрами на разные недели и вручную заменять рецепты в рационах.
          </p>
        </div>
      )}
      {!isLoading && rationList && rationList.length > 0 && (
        <div className="ration-item-list">
          {rationList.map((item) => (
            <RationItem item={item} key={item.id} />
          ))}
        </div>
      )}
    </PageContent>
  )
}

export default RationList
