import { Link } from 'react-router-dom'
import LogoImage from '../../assets/mi-logo.png'

const Logo = () => {
  return (
    <div className="main-logo">
      <Link to="/">
        <img src={LogoImage} alt="Miuka.Рецепты" />
      </Link>
    </div>
  )
}

export default Logo
