import { useBodyClass } from '../../helpers'
import PageContent from '../UI/PageWrapper'

const WWPayment = () => {
  useBodyClass('page-ww-payment')

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Оплата из-за рубежа и стран СНГ</h1>
      </div>
      <div className="page-description">
        <h3>Как оплатить зарубежными картами или PayPal?</h3>
        <p>Для оплаты из-за рубежа и СНГ:</p>
        <ul>
          <li>
            Перейдите на{' '}
            <a
              href="https://boosty.to/miukagirl/single-payment/donation/send/author"
              target="_blank"
              rel="noreferrer"
            >
              наш профиль в Boosty
            </a>{' '}
            и войдите в ваш профиль используя социальные сети или email.
          </li>
          <li>
            Нажмите кнопку{' '}
            <a
              href="https://boosty.to/miukagirl/single-payment/donation/send/author"
              target="_blank"
              rel="noreferrer"
            >
              "Отправить донат"
            </a>{' '}
            на нашей странице и введите сумму в USD согласно цене для зарубежной
            оплаты (см. ниже).
          </li>
          <li>
            В сообщении к донату <strong>обязательно укажите ваш email</strong>{' '}
            в системе Mi-Recipes и какой тариф вы покупаете.
          </li>
          <li>
            Оплатите донат. В течении дня после проверки ваш аккаунт будет
            активирован на выбранный период.
          </li>
          <li>
            По окончанию оплаченного периода вам нужно будет снова продлить его
            следуя данной инструкции.
          </li>
        </ul>
        <p>
          <a
            className="button button-fixed"
            href="https://boosty.to/miukagirl/single-payment/donation/send/author"
            target="_blank"
            rel="noreferrer"
          >
            Оплатить через Boosty
          </a>
        </p>
        <h3>Стоимость подписки для зарубежной оплаты</h3>
        <ul>
          <li>
            <strong>На 3 месяца:</strong> 20$
          </li>
          <li>
            <strong>На полгода:</strong> 30$
          </li>
          <li>
            <strong>На год:</strong> 40$
          </li>
          <li>
            <strong>Вечный доступ:</strong> 70$
          </li>
        </ul>
        <p>
          Если вы приобретали <strong>Основную книгу рецептов</strong> (отдельно
          или в бандле с другими книгами) у вас скидка 50% на оплату любой
          подписки. Укажите это в примечании к донату и оплатите половину
          стоимости в $. Email с которого покупалась книга должен совпадать с
          email вашего аккаунта в Mi-Recipes.
        </p>
        <p>
          Пожалуйста убедитесь что вы оплачиваете верную сумму, платежи через
          систему Boosty невозможно отменить и вернуть.
        </p>
      </div>
    </PageContent>
  )
}

export default WWPayment
