import Message from './Message'

const DemoModeMessage = () => {
  return (
    <Message
      content="Недоступно в демо режиме без активного тарифа."
      type="error"
    />
  )
}

export default DemoModeMessage
