import { authActions } from './auth-slice'

// Action creators functions
export const processLogin = (user, token) => {
  return (dispatch) => {
    localStorage.setItem('isLogged', true)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('token', token)

    console.log(localStorage.getItem('user'))
    dispatch(authActions.login({ user: user, token: token }))
  }
}

export const checkLocalLogin = () => {
  return (dispatch) => {
    const isLogged = localStorage.getItem('isLogged')
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token')

    if (isLogged && user && token !== '') {
      dispatch(authActions.login({ user: user, token: token }))
    }
  }
}

export const processLogout = () => {
  return (dispatch) => {
    localStorage.removeItem('isLogged')
    localStorage.removeItem('user')
    localStorage.removeItem('token')

    dispatch(authActions.logout())
  }
}

export const setUserData = (user) => {
  return (dispatch) => {
    localStorage.setItem('user', JSON.stringify(user))
    dispatch(authActions.setUserData({ user: user }))
  }
}
