import { NavLink } from 'react-router-dom'

import {
  IoNewspaperOutline,
  IoHeartOutline,
  IoStorefrontOutline,
  IoCalendarOutline,
  IoImagesOutline,
} from 'react-icons/io5'
import { useSelector } from 'react-redux'

const TabBar = () => {
  const user = useSelector((state) => state.auth.user)

  return (
    <div className="tab-bar">
      <div className="tab-bar-item">
        <NavLink
          to="/recipes"
          className={(navData) => {
            return navData.isActive ? 'active' : ''
          }}
        >
          <IoNewspaperOutline />
          Рецепты
        </NavLink>
      </div>
      <div className="tab-bar-item">
        <NavLink
          to="/ration"
          className={(navData) => {
            return navData.isActive ? 'active' : ''
          }}
        >
          <IoCalendarOutline />
          Рацион
        </NavLink>
      </div>
      <div className="tab-bar-item">
        {user.shoplist_count > 0 && (
          <div className="tab-bar-badge">{user.shoplist_count}</div>
        )}
        <NavLink
          to="/shoplist"
          className={(navData) => {
            return navData.isActive ? 'active' : ''
          }}
        >
          <IoStorefrontOutline />
          Шоплист
        </NavLink>
      </div>
      <div className="tab-bar-item">
        {user.favourites_count > 0 && (
          <div className="tab-bar-badge">{user.favourites_count}</div>
        )}
        <NavLink
          to="/favorites"
          className={(navData) => {
            return navData.isActive ? 'active' : ''
          }}
        >
          <IoHeartOutline />
          Избранное
        </NavLink>
      </div>
      <div className="tab-bar-item">
        <NavLink
          to="/blog"
          className={(navData) => {
            return navData.isActive ? 'active' : ''
          }}
        >
          <IoImagesOutline />
          Блог
        </NavLink>
      </div>
    </div>
  )
}

export default TabBar
