import { IoCheckmark } from 'react-icons/io5'

const StepItemDone = (props) => {
  return (
    <div
      className="step-item-wrapper step-item-wrapper-done"
      onClick={props.markRecipeAsDone}
    >
      <div className="step-item-index">
        <IoCheckmark title="Отметить приготовленным" />
      </div>
      <div className="step-item-text">Отметить рецепт приготовленным</div>
    </div>
  )
}

export default StepItemDone
