import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderRecipeLine = (props) => (
  <ContentLoader
    speed={2}
    width={350}
    height={61}
    viewBox="0 0 350 61"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect width="76.3015" height="60.7375" rx="10" fill="#D9D9D9" />
    <rect
      x="87.6898"
      y="0.379608"
      width="60.7375"
      height="16.3232"
      rx="5"
      fill="#D9D9D9"
    />
    <rect
      x="87.6898"
      y="23.5358"
      width="262.31"
      height="14.4252"
      rx="5"
      fill="#D9D9D9"
    />
    <rect
      x="87.6898"
      y="43.2755"
      width="54.6638"
      height="13.6659"
      rx="5"
      fill="#D9D9D9"
    />
  </ContentLoader>
)

export default LoaderRecipeLine
