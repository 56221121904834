import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderRecipeCard = (props) => (
  <ContentLoader
    speed={2}
    width={310}
    height={185}
    viewBox="0 0 310 185"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" width="310" height="185" rx="10" ry="10" />
  </ContentLoader>
)

export default LoaderRecipeCard
