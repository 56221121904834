import { useState } from 'react'
import OzonLogo from '../../assets/icon-ozon.png'
import WBLogo from '../../assets/icon-wb.png'
import OtherLogo from '../../assets/icon-mp.png'
import { AiOutlineLink } from 'react-icons/ai'

const Links = (props) => {
  const [isOpened, setIsOpened] = useState(false)

  const toggleLinksHandler = (e) => {
    setIsOpened((prevState) => {
      return !prevState
    })
  }

  return (
    <div className="recipe-links-wrapper">
      <div className="recipe-links-title" onClick={toggleLinksHandler}>
        <AiOutlineLink />
        {'  '} {!isOpened ? 'Показать' : 'Скрыть'} использованные продукты
      </div>
      {isOpened ? (
        <div className="recipe-links-list">
          {props.links.map((item) => (
            <a href={item.url} target="_blank" rel="noreferrer" key={item.url}>
              <img
                src={
                  item.url.indexOf('ozon') !== -1
                    ? OzonLogo
                    : item.url.indexOf('wildberries') !== -1
                    ? WBLogo
                    : OtherLogo
                }
                alt="Маркетплейс"
              />
              {item.text}
            </a>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Links
