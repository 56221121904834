import 'swiper/css'
import LoaderShoplist from '../loader/LoaderShoplist'
import Message from '../UI/Message'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { tags_slider_settings } from '../../config/swiperslider'
import { fetchTagsList } from '../utils/http'

const TagsSectionSlider = (props) => {
  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const [swiper, setSwiper] = useState(null)

  const nextSlideHandler = () => {
    swiper?.slideNext()
  }

  const prevSlideHandler = () => {
    swiper?.slidePrev()
  }

  const payload = user
    ? {
        user_id: user.id,
        token: token,
      }
    : null

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['tagslist'],
    queryFn: ({ signal }) => fetchTagsList({ signal, payload }),
  })

  let content = ''

  if (isError) {
    content = <Message content={error.info?.message} type="error" />
  }

  if (isLoading) {
    content = <LoaderShoplist />
  }

  if (data) {
    content = (
      <div className="recipes-list">
        <Swiper {...tags_slider_settings} onSwiper={setSwiper}>
          {data.map((tag) => (
            <SwiperSlide key={tag.id}>
              <div key={tag.id} className="tag-item-wrapper">
                <Link
                  to={`latest/tag/${tag.id}`}
                  state={{ title: 'По тегу #' + tag.title }}
                >
                  #{tag.title}
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  }

  return (
    <div className="page-section section-tags-slider">
      <div className="section-title">
        <h2>{props.title}</h2>
        <div className="slider-nav">
          <div className="nav-button" onClick={prevSlideHandler}>
            <MdKeyboardArrowLeft fontSize="1.4rem" />
          </div>
          <div className="nav-button" onClick={nextSlideHandler}>
            <MdKeyboardArrowRight fontSize="1.4rem" />
          </div>
        </div>
      </div>
      {content}
    </div>
  )
}

export default TagsSectionSlider
