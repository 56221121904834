import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { processLogout } from '../../store/auth-actions'
import Logo from './Logo'
import { FaUserCog, FaSignOutAlt, FaBook } from 'react-icons/fa'
import { IoChevronBackOutline } from 'react-icons/io5'

const UserHeader = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const showBackButton =
    (location.pathname.indexOf('/recipe/') === -1 ? false : true) ||
    (location.pathname.indexOf('/ration/') === -1 ? false : true)

  const logoutHandler = () => {
    dispatch(processLogout())
  }

  const goBackHandler = () => {
    navigate(-1)
  }

  return (
    <div className="user-header">
      <div className="title">
        {!!showBackButton && (
          <div className="go-back-button" onClick={goBackHandler}>
            <IoChevronBackOutline title="Назад" />
          </div>
        )}
        <Logo />
      </div>
      <div className="user-header-nav">
        <Link to="/account">
          <FaUserCog title="Мой аккаунт" />
          <span>Мой аккаунт</span>
        </Link>
        <a
          href="https://mirecipes.ru/guide/"
          target="_blank"
          rel="nofollow noreferrer"
        >
          <FaBook title="Справка" />
          <span>Справка</span>
        </a>
        <div className="logout-button" onClick={logoutHandler}>
          <FaSignOutAlt title="Выйти" />
          <span>Выйти</span>
        </div>
      </div>
    </div>
  )
}

export default UserHeader
