import LoaderRecipeLine from '../loader/LoaderRecipeLine'
import Message from '../UI/Message'
import PageContent from '../UI/PageWrapper'
import RecipeCard from '../recipes/RecipeCard'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useBodyClass } from '../../helpers'
import { fetchRecipesList } from '../utils/http'

const RecipesList = (props) => {
  useBodyClass('page-recipes-list')

  const location = useLocation()
  const title = location.state?.title ? location.state.title : 'Из категории'
  let { type, tag } = useParams()

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  const payload = user
    ? {
        type: type,
        tag: tag,
        user_id: user.id,
        token: token,
      }
    : null

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['recipeslist', { type: type, tag: tag }],
    queryFn: ({ signal }) => fetchRecipesList({ signal, payload }),
  })

  let content = ''

  if (isError) {
    content = <Message content={error.info?.message} type="error" />
  }

  if (isLoading) {
    content = <LoaderRecipeLine />
  }

  if (data) {
    content = (
      <div className="recipes-list">
        {isLoading && <LoaderRecipeLine />}
        {data.data?.map((recipe) => (
          <RecipeCard
            type="line"
            key={recipe.id}
            title={recipe.title}
            id={recipe.id}
            image={recipe.photos}
            categories={recipe.categories}
            time_prepare={recipe.time_prepare}
            time_cook={recipe.time_cook}
            video={recipe.video}
          />
        ))}
      </div>
    )
  }

  return (
    <PageContent>
      <div className="page-section page-section-list">
        <div className="section-title">
          <h2>
            <Link to="/recipes/">Рецепты</Link> / {title} ({data?.total || 0})
          </h2>
        </div>
        {content}
      </div>
    </PageContent>
  )
}

export default RecipesList
