import DemoModeMessage from '../UI/DemoModeMessage'

const RecipeVideo = (props) => {
  const url = props.url + '?modestbranding=1&autoplay=1&rel=0&loop=1'

  return (
    <div className="recipe-video-page">
      <div className="recipe-video-wrapper">
        {props.url === '-' ? (
          <DemoModeMessage />
        ) : (
          <iframe
            width="640"
            height="1137"
            src={url}
            title="Видео рецепт"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            className="recipe-video"
          ></iframe>
        )}
      </div>
    </div>
  )
}

export default RecipeVideo
