const Message = (props) => {
  return (
    <div className="message-wrapper">
      <div
        dangerouslySetInnerHTML={{ __html: props.content }}
        className={`message ${props.type}`}
      ></div>
    </div>
  )
}

export default Message
