import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderShoplist = (props) => {
  return (
    <ContentLoader viewBox="0 0 250 15" width={250} height={30} {...props}>
      <rect x="2" y="0" rx="15" ry="15" width="15" height="15" />
      <rect x="25" y="0" rx="7" ry="7" width="220" height="15" />
    </ContentLoader>
  )
}

export default LoaderShoplist
