import { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { getCookie } from '../../helpers'

const MessageFullwidth = (props) => {
  const type = props.type ?? 'info'
  const dismissable = props.dismissable ?? false
  const [display, setDisplay] = useState(true)

  const closeHandler = () => {
    setDisplay(false)
    const now = new Date()
    const expirationDate = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000) // 1 week
    document.cookie =
      'messagedismissed=' +
      props.id +
      '; expires=' +
      expirationDate.toUTCString() +
      '; path=/'
  }

  if (!display || getCookie('messagedismissed') === props.id?.toString()) {
    return ''
  }

  return (
    <div
      className={`message message-fullwidth ${type} ${
        dismissable ? 'dismissable' : ''
      }`}
    >
      {props.children}
      <span dangerouslySetInnerHTML={{ __html: props.content }} />{' '}
      {props.link ? (
        <Link to={props.link.url} target={props.link.target}>
          {props.link.text}
        </Link>
      ) : null}
      {dismissable ? (
        <div className="message-close-button" onClick={closeHandler}>
          <MdClose size="1.2em" />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default MessageFullwidth
