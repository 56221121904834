const Sidebar = (props) => {
  return (
    <div className="col-2col page-auth">
      <div className="col-left"></div>
      <div className="col-right">{props.children}</div>
    </div>
  )
}

export default Sidebar
