import IngredientItem from '../recipe/IngredientItem'
import LoaderShoplist from '../loader/LoaderShoplist'
import Message from '../UI/Message'
import PageContent from '../UI/PageWrapper'
import { useCallback, useEffect, useState } from 'react'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { API_REFRESH, API_URL } from '../../config/main'
import { useBodyClass } from '../../helpers'
import { setUserData } from '../../store/auth-actions'

const ShopList = () => {
  useBodyClass('page-shoplist')

  const dispatch = useDispatch()

  const [shopList, setShopList] = useState([])
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  // Clear shoplist
  const clearShoplist = useCallback(async (payload) => {
    setMessage(null)

    try {
      const response = await fetch(
        API_URL +
          `?act=clearshoplist&user_id=${payload.user_id}&token=${payload.token}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        toast.success('Шоплист очищен')
        setShopList([])
        dispatch(
          setUserData({
            ...user,
            shoplist_count: 0,
          })
        )
      }
    } catch (error) {
      setMessage({ type: 'error', text: error.message })
    }
  }, [])

  // Clean shoplist
  const clearShoplistHandler = () => {
    if (window.confirm('Очистить шоплист?')) {
      clearShoplist({
        user_id: user.id,
        token: token,
      })
    }
  }

  // Remove from shoplist
  const removeFromShopListHandler = (title) => {
    setShopList(
      shopList.filter((item) => {
        return item !== title
      })
    )
  }

  // Fetch shoplist
  const fetchShopList = useCallback(async (payload) => {
    setMessage(null)
    setIsLoading(true)

    try {
      const response = await fetch(
        API_URL +
          `?act=getshoplist&user_id=${payload.user_id}&token=${payload.token}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setIsLoading(false)

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        // if (isObjEqual(data.data, shopList)) {
        //   console.log('Shoplist data does not changed.')
        // } else {
        //   console.log('Shoplist data changed. Update.')
        //   setShopList(data.data)
        // }
        setShopList(data.data)
      }
    } catch (error) {
      setIsLoading(false)
      setMessage({ type: 'error', text: error.message })
    }
  }, [])

  // Use effects
  useEffect(() => {
    fetchShopList({
      user_id: user.id,
      token: token,
    })
  }, [fetchShopList, token, user.id])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchShopList({ user_id: user.id, token: token })
    }, API_REFRESH)

    return () => clearInterval(interval)
  }, [fetchShopList, user.id, token])

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Шоплист ({shopList.length})</h1>
        <div className="button-remove" onClick={clearShoplistHandler}>
          <IoCloseCircleOutline />
        </div>
      </div>
      <div className="page-section page-section-list">
        {message && <Message content={message.text} type={message.type} />}
        <div className="recipes-list">
          {shopList.length === 0 && !isLoading && (
            <p>
              У вас нет ингредиентов в шоплисте. Вы можете добавить их на
              странице рецепта.
            </p>
          )}
          {shopList.map((item, index) => (
            <IngredientItem
              added={true}
              shoplist={true}
              key={`${index}+${item}`}
              id={`${index}+${item}`}
              title={item}
              onRemove={removeFromShopListHandler}
            />
          ))}
          {isLoading && <LoaderShoplist />}
        </div>
      </div>
    </PageContent>
  )
}

export default ShopList
