import RecipeCategory from './RecipeCategory'
import { motion } from 'framer-motion'
import { MdOndemandVideo } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { displayCookingTime } from '../../helpers'

import {
  motionTransition,
  motionVariantsRecipeCard,
  motionVariantsList,
  PHOTOS_URL,
} from '../../config/main'

import {
  IoCloseCircleOutline,
  IoTimerOutline,
  IoTimeOutline,
} from 'react-icons/io5'

const RecipeCard = (props) => {
  const cardCSSClass =
    props.type === 'line' ? 'recipe-card recipe-card-line' : 'recipe-card'

  const addToFavHandler = (e) => {
    e.stopPropagation()
    e.preventDefault()

    props.onAddToFav(props.id)
  }

  return (
    <motion.div
      key={props.id}
      initial="initial"
      animate="in"
      exit="out"
      layout
      variants={
        props.type === 'line' ? motionVariantsList : motionVariantsRecipeCard
      }
      transition={motionTransition}
    >
      <div className={cardCSSClass}>
        <Link to={`/recipe/${props.id}`}>
          <div
            className="recipe-image"
            style={{
              backgroundImage:
                "url('" + PHOTOS_URL + 'thumb_' + props.image + "')",
            }}
          >
            <div className="recipe-image-overlay"></div>
          </div>

          <div className="recipe-inner">
            {props.video && (
              <div className="recipe-icons">
                <MdOndemandVideo />
              </div>
            )}
            <div className="recipe-categories">
              {props.categories.map((item) => (
                <RecipeCategory key={item.id} title={item.title} />
              ))}
            </div>
            <div className="recipe-title">{props.title}</div>
            <div className="recipe-time">
              <IoTimeOutline fontSize="1.3rem" />{' '}
              {displayCookingTime(props.time_prepare, 'short')}
              <IoTimerOutline fontSize="1.3rem" />{' '}
              {displayCookingTime(props.time_cook, 'short')}
            </div>
          </div>

          {props.showFav && (
            <div
              className="recipe-add-to-fav"
              onClick={addToFavHandler}
              title="Удалить из Избранного?"
            >
              <IoCloseCircleOutline fontSize="2.2rem" />
            </div>
          )}
        </Link>
      </div>
    </motion.div>
  )
}

export default RecipeCard
