import { useState } from 'react'
import { IoSearchOutline, IoCloseCircleOutline } from 'react-icons/io5'

const SearchBox = (props) => {
  const [query, setQuery] = useState('')

  const onChangeQueryHandler = (e) => {
    setQuery(e.target.value)
  }

  const clearQueryHandler = (e) => {
    setQuery('')
  }

  const submitQueryHandler = () => {
    if (query) {
      props.onSearchSubmit(query)
      setQuery('')
    }
  }

  const onKeyUpHandler = (e) => {
    if (e.key === 'Enter') {
      submitQueryHandler()
    }
  }

  return (
    <div className="search-box">
      <input
        type="text"
        placeholder="Поиск по названию и ингредиентам..."
        onChange={onChangeQueryHandler}
        value={query}
        onKeyUp={onKeyUpHandler}
      />
      {query && (
        <div className="button-clear" onClick={clearQueryHandler}>
          <IoCloseCircleOutline />
        </div>
      )}
      <div className="button-search" onClick={submitQueryHandler}>
        <IoSearchOutline />
      </div>
    </div>
  )
}

export default SearchBox
