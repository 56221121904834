import { MdClose } from 'react-icons/md'
import { Fragment } from 'react'
import ReactDOM from 'react-dom'

const ModalDialog = (props) => {
  const closeHandler = () => {
    props.onClose()
  }

  const portalElement = document.getElementById('modals')
  const cssClass = 'modal-dialog ' + props.className

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Fragment>
          <div className="modal-backdrop" onClick={closeHandler}></div>
          <div className={cssClass}>
            {props.title && <h3>{props.title}</h3>}
            <div className="modal-close-button" onClick={closeHandler}>
              <MdClose size="1.2em" />
            </div>
            {props.children}
          </div>
        </Fragment>,
        portalElement
      )}
    </Fragment>
  )
}

export default ModalDialog
