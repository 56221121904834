import Message from '../UI/Message'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { API_URL } from '../../config/main'

const AddToRationForm = (props) => {
  const field_ration_id = useRef()
  const field_ration_day = useRef()
  const field_ration_meal = useRef()

  const [rationList, setRationsList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  // Fetch rations list
  const fetchRationsList = useCallback(async (payload) => {
    //setMessage(null)
    setIsLoading(true)

    try {
      const response = await fetch(
        API_URL +
          `?act=getrationslist&user_id=${payload.user_id}&token=${payload.token}`
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      setIsLoading(false)

      if (data.type === 'error') {
        //setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        setRationsList(data.data)
      }
    } catch (error) {
      setIsLoading(false)
      //setMessage({ type: 'error', text: error.message })
    }
  }, [])

  // Use effects
  useEffect(() => {
    fetchRationsList({
      user_id: user.id,
      token: token,
    })
  }, [fetchRationsList, token, user.id])

  const formSubmitHandler = (e) => {
    e.preventDefault()

    props.onSubmit({
      ration_id: field_ration_id.current.value,
      ration_day: field_ration_day.current.value,
      ration_meal: field_ration_meal.current.value,
    })
  }

  return (
    <div className="form-wrapper">
      <form onSubmit={formSubmitHandler}>
        <div className="form-input-wrapper">
          <label>Рацион</label>
          {!isLoading && rationList && rationList.length > 0 && (
            <select ref={field_ration_id}>
              {rationList.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          )}
          {!isLoading && !rationList && (
            <Message
              content="Создайте новый рацион в разделе 'Рацион' перед добавлением рецепта."
              type="error"
            />
          )}
          <span className="form-description">
            Выберите рацион для замены рецепта.
          </span>
        </div>
        <div className="form-input-wrapper">
          <label>День недели</label>
          <select ref={field_ration_day}>
            <option value="0">Понедельник</option>
            <option value="1">Вторник</option>
            <option value="2">Среда</option>
            <option value="3">Четверг</option>
            <option value="4">Пятница</option>
            <option value="5">Суббота</option>
            <option value="6">Воскресенье</option>
          </select>
        </div>
        <div className="form-input-wrapper">
          <label>Прием пищи</label>
          <select ref={field_ration_meal}>
            <option value="0">Завтрак</option>
            <option value="1">Обед</option>
            <option value="2">Ужин</option>
            <option value="3">Перекус</option>
          </select>
          <span className="form-description">
            Новый рецепт заменит старый рецепт в существующем рационе.
          </span>
        </div>
        <div className="form-input-wrapper">
          {!isLoading && rationList && rationList.length > 0 && (
            <input type="submit" value="Добавить в рацион" />
          )}
        </div>
      </form>
    </div>
  )
}

export default AddToRationForm
