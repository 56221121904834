import LoaderRecipeLine from '../loader/LoaderRecipeLine'
import Message from '../UI/Message'
import PageContent from '../UI/PageWrapper'
import RecipeCard from '../recipes/RecipeCard'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { API_REFRESH, API_URL } from '../../config/main'
import { isObjEqual, useBodyClass } from '../../helpers'
import { setUserData } from '../../store/auth-actions'

const Favorites = () => {
  useBodyClass('page-favs-list')

  let { type, tag } = useParams()

  const dispatch = useDispatch()
  const [recipes, setRecipes] = useState([])
  const [message, setMessage] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)

  // Fetch recipes
  const fetchRecipesList = useCallback(
    async (payload) => {
      setMessage(null)
      setIsLoading(true)

      try {
        const response = await fetch(
          API_URL +
            `?act=getrecipeslist&user_id=${payload.user_id}&token=${payload.token}&type=${payload.type}&tag=${payload.tag}`
        )

        if (!response.ok) {
          throw new Error('Ошибка доступа к API.')
        }

        const data = await response.json()

        setIsLoading(false)

        if (data.type === 'error') {
          setMessage({ type: 'error', text: data.message })
        }

        if (data.type === 'success') {
          if (isObjEqual(data.data, recipes)) {
            console.log('Favourites data does not changed.')
          } else {
            console.log('Favourites data changed. Update.')
            setRecipes(data.data)
          }
        }
      } catch (error) {
        setIsLoading(false)
        setMessage({ type: 'error', text: error.message })
      }
    },
    [recipes]
  )

  // Remove from favourites
  const addToFavHandler = (id) => {
    addToFavRequest({ id: id, user_id: user.id, token })
  }

  const addToFavRequest = async (payload) => {
    // id
    try {
      const response = await fetch(
        API_URL +
          `?act=addtofav&user_id=${payload.user_id}&token=${payload.token}`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        }
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      if (data.type === 'error') {
        setMessage({ type: 'error', text: data.message })
      }

      if (data.type === 'success') {
        const updatedRecipes = recipes.filter((el) => {
          return el.id !== payload.id
        })

        dispatch(
          setUserData({
            ...user,
            favourites_count: user.favourites_count - 1,
          })
        )

        setRecipes(updatedRecipes)
      }
    } catch (error) {
      setMessage({ type: 'error', text: error.message })
    }
  }

  // Use effects
  useEffect(() => {
    fetchRecipesList({
      type: 'favorites',
      tag: 0,
      user_id: user.id,
      token: token,
    })
  }, [fetchRecipesList, token, type, user.id, tag])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchRecipesList({
        type: 'favorites',
        tag: 0,
        user_id: user.id,
        token: token,
      })
    }, API_REFRESH)

    return () => clearInterval(interval)
  }, [fetchRecipesList, token, type, user.id, tag])

  return (
    <PageContent>
      <div className="page-title-wrapper">
        <h1 className="page-title">Избранное ({recipes.length})</h1>
      </div>
      <div className="page-section page-section-list">
        {message && <Message content={message.text} type={message.type} />}
        <div className="recipes-list">
          {recipes.length === 0 && !isLoading && (
            <p>
              У вас нет рецептов в избранном. Вы можете добавить рецепт в
              избранное на странице рецепта.
            </p>
          )}
          {recipes.map((recipe) => (
            <RecipeCard
              type="line"
              key={recipe.id}
              title={recipe.title}
              id={recipe.id}
              image={recipe.photos}
              categories={recipe.categories}
              time_prepare={recipe.time_prepare}
              time_cook={recipe.time_cook}
              showFav={true}
              onAddToFav={addToFavHandler}
              video={recipe.video}
            />
          ))}
          {isLoading && <LoaderRecipeLine />}
        </div>
      </div>
    </PageContent>
  )
}

export default Favorites
