import { QueryClient } from '@tanstack/react-query'
import { API_URL, SITE_URL } from '../../config/main'

export const queryClient = new QueryClient()

// Fetch blog posts
export async function fetchBlogPosts({ signal }) {
  const url =
    SITE_URL +
    `wp-json/wp/v2/posts?_embed&per_page=100&rnd=` +
    Math.floor(Math.random() * 1000)

  const response = await fetch(url, { signal: signal })

  if (!response.ok) {
    const error = new Error('An error occurred while fetching blog posts.')
    error.code = response.status
    error.info = 'Ошибка получения публикаций.'
    throw error
  }

  const data = await response.json()

  return data
}

// Fetch user data
export async function fetchUserData({ signal, payload }) {
  const url =
    API_URL +
    `?act=getuserdata&user_id=${payload.user_id}&token=${payload.token}`

  const response = await fetch(url, { signal: signal })

  if (!response.ok) {
    const error = new Error('An error occurred while fetching user data.')
    error.code = response.status
    error.info = await response.json()
    throw error
  }

  const { data } = await response.json()

  return data
}

// Fetch recipes list
export async function fetchRecipesList({ signal, payload }) {
  const limit = payload.limit ? payload.limit : 0
  const tag = payload.tag ? payload.tag : 0

  const url =
    API_URL +
    `?act=getrecipeslist&user_id=${payload.user_id}&token=${payload.token}&type=${payload.type}&tag=${tag}&limit=${limit}`

  const response = await fetch(url, { signal: signal })

  if (!response.ok) {
    const error = new Error(
      'Ошибка доступа к API. Подождите или попробуйте обновить страницу. Если ошибка повторяется нажмите "Обновить приложение" в разделе "Мой аккаунт".'
    )
    error.code = response.status
    error.info = await response.json()
    throw error
  }

  const data = await response.json()

  return data
}

// Fetch tags list
export async function fetchTagsList({ signal, payload }) {
  const url =
    API_URL +
    `?act=gettagslist&user_id=${payload.user_id}&token=${payload.token}`

  const response = await fetch(url, { signal: signal })

  if (!response.ok) {
    const error = new Error(
      'Ошибка доступа к API. Подождите или попробуйте обновить страницу.'
    )
    error.code = response.status
    error.info = await response.json()
    throw error
  }

  const { data } = await response.json()

  return data
}
