const PostCard = (props) => {
  return (
    <div className="post-card">
      {props.image ? (
        <div className="post-image">
          <a target="_blank" rel="nofollow noreferrer" href={props.link}>
            <img src={props.image} alt={props.title} />
          </a>
          <div className="post-category">{props.category}</div>
        </div>
      ) : (
        ''
      )}
      <div className="post-title">
        <a target="_blank" rel="nofollow noreferrer" href={props.link}>
          <h2>{props.title}</h2>
        </a>
      </div>
    </div>
  )
}

export default PostCard
