import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { API_URL } from '../../config/main'
import { setUserData } from '../../store/auth-actions'

import {
  IoAddCircleOutline,
  IoRemoveCircleOutline,
  IoCheckmarkCircleOutline,
} from 'react-icons/io5'

const IngredientItem = (props) => {
  const dispatch = useDispatch()

  const [added, setAdded] = useState(props.added)
  const user = useSelector((state) => state.auth.user)
  const token = useSelector((state) => state.auth.token)
  const notasection = props.title.charAt(0) !== '-'

  const addToShopListHandler = () => {
    addToshopListRequest({ title: props.title, user_id: user.id, token })

    if (props.onRemove) {
      props.onRemove(props.title)
    }
  }

  const addToshopListRequest = async (payload) => {
    // id
    try {
      const response = await fetch(
        API_URL +
          `?act=addtoshoplist&user_id=${payload.user_id}&token=${payload.token}`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        }
      )

      if (!response.ok) {
        throw new Error('Ошибка доступа к API.')
      }

      const data = await response.json()

      if (data.type === 'error') {
        console.log(data.message)
      }

      if (data.type === 'success') {
        setAdded(data.data)
        dispatch(
          setUserData({
            ...user,
            shoplist_count: data.data
              ? user.shoplist_count + 1
              : user.shoplist_count - 1,
          })
        )
        toast.success(
          data.data
            ? 'Ингредиент добавлен в шоплист.'
            : 'Ингредиент удален из шоплиста.'
        )
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  if (props.title === '') return

  return notasection ? (
    <div
      className={`ingredient-item-wrapper ${added ? 'added' : ''}`}
      onClick={addToShopListHandler}
      title={added ? 'Удалить из шоплиста' : 'Добавить в шоплист'}
    >
      <div className="ingredient-add-to-shoplist">
        {added ? (
          props.shoplist ? (
            <IoCheckmarkCircleOutline fontSize="1.2rem" />
          ) : (
            <IoRemoveCircleOutline fontSize="1.2rem" />
          )
        ) : (
          <IoAddCircleOutline fontSize="1.2rem" />
        )}
      </div>
      <div className="ingredient-title">{props.title}</div>
    </div>
  ) : (
    <div className="ingredient-item-wrapper ingredient-section">
      <div className="ingredient-title">{props.title}</div>
    </div>
  )
}

export default IngredientItem
